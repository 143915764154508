import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";
import { Fragment, useEffect} from "react";
import { insertLogs } from "../../homepurchase.service";
import PurchasePrice from "../Tab2/components/PurchasePrice";
import { useMediaQuery } from "react-responsive";

const amortizationYears = [
    { name: "15 Years", code: 15 },
    { name: "30 Years", code: 30 },
    { name: "40 Years", code: 40 }
]; 


const secondAmortizationYears = [
    { name: "12 Years", code: 12 },
]; 

const Tab4 = ({ handleSettingsInputChange, handleSettingsSave, settings, handleLeftCalculations }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px) and (max-width: 1024px)'
      });

    useEffect(() => {
        const payload = {
            description: "View Settings",
        };
        insertLogs(payload);
    }, []);

    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="lg:col-4 md:col-5"></div>
                    <div className="col-6 align-text-left">
                        <Button
                            label="Save"
                            className="p-button-raised p-button-warning p-button-lg"
                            onClick={() => handleSettingsSave()}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
          
            <Panel
                header={`${isDesktopOrLaptop ? "" : "Settings"}`}
                className={`mb-2 ${isDesktopOrLaptop ? "OwnHouse" : "Settings"}`}
            >
                <div className={`mb-2 ${isDesktopOrLaptop ? "align-text" : ""} ${isTablet ? "w-90" : ""}`}>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">
                                First Loan Amortization
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <Dropdown
                                    className="vw-dropdown vw-dropdown-with-border"
                                    value={settings?.amortizationYears}
                                    options={amortizationYears}
                                    required={true}
                                    name="amortizationYears"
                                    onChange={handleSettingsInputChange}
                                    optionLabel="name"
                                    optionValue="code"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">
                                Second Loan Amortization
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <Dropdown
                                    className="vw-dropdown vw-dropdown-with-border"
                                    value={settings?.secondAmortizationYears}
                                    options={secondAmortizationYears}
                                    required={true}
                                    name="secondAmortizationYears"
                                    onChange={handleSettingsInputChange}
                                    optionLabel="name"
                                    optionValue="code"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="">
                                Max Mortgage amount for Tax Deduction on First
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="taxDeductionOnFirst"
                                        prefix="$ "
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.taxDeductionOnFirst}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="">
                                Max Mortgage amount for Tax Deduction on Second
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="taxDeductionOnSecond"
                                        prefix="$ "
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.taxDeductionOnSecond}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Property Taxes</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="propertyTax"
                                        suffix=" %"
                                        min={0} max={100}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.propertyTax}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-2">
                                Cap on Itemization of Property Taxes
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        prefix="$ "
                                        name="capItemizationPropertyTax"
                                        disabled={false}
                                        value={
                                            settings?.capItemizationPropertyTax
                                        }
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Standard Deduction</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="standardDeduction"
                                        disabled={false}
                                        prefix="$ "
                                        value={settings?.standardDeduction}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Federal Tax Rate</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    {/* <InputText
                                        className="vw-input-nfl-border"
                                        autoComplete="off"
                                        required={true}
                                        name="federalTaxRate"
                                        disabled={false}
                                        value={settings?.federalTaxRate}
                                        onChange={handleSettingsInputChange}
                                        maxLength={2}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    /> */}
                                     <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="federalTaxRate"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.federalTaxRate}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">State Tax Rate</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="stateTaxRate"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.stateTaxRate}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                    {/* <InputText
                                        className="vw-input-nfl-border"
                                        autoComplete="off"
                                        required={true}
                                        name="stateTaxRate"
                                        disabled={false}
                                        value={settings?.stateTaxRate}
                                        onChange={handleSettingsInputChange}
                                        maxLength={2} 
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Rental Increase</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="rentalIncrease"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        disabled={false}
                                        min={0} max={100}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.rentalIncrease ? settings?.rentalIncrease : 10}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Average US Appreciation</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="averageAppreciation"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.averageAppreciation ? settings?.averageAppreciation : 7}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Increase in Property Taxes</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="increasePropertyTax"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        disabled={false}
                                        min={0} max={100}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.increasePropertyTax ? settings?.increasePropertyTax : 2}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Inflation Rate</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="inflationRate"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.inflationRate ? settings?.inflationRate : 3}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Add Interest on Equity </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="interestOnEquity"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        suffix=" %"
                                        disabled={false}
                                        min={0} max={100}
                                        onValueChange={
                                            handleSettingsInputChange
                                        }
                                        value={settings?.interestOnEquity ? settings?.interestOnEquity : 0}
                                        // value={leftContentsettings?.purchasePrice ? FormatAmount(leftContentsettings?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {renderFooter()}
                </div>
            </Panel>
        </Fragment>
    );
};

export default Tab4;