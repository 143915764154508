import { FormatAmount } from "app/utils/utility.service";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Fragment, useEffect, useState } from "react";
import { insertLogs } from "../../homepurchase.service";
import DirectIncomeGraphModal from "./DirectIncomeGraphModal";
import DirectIncomeModal from "./DirectIncomeModal";
import IndirectIncomeGraphModal from "./IndirectIncomeGraphModal";
import IndirectIncomeModal from "./IndirectIncomeModal";
import SelfRental from "./Rental/SelfRental/SelfRental";
import SpouseRental from "./Rental/SpouseRental/SpouseRental";
import TotalDebtGraphModal from "./TotalDebtGraphModal";
import TotalDebtModal from "./TotalDebtModal";
import { useMediaQuery } from "react-responsive";
import PurchasePrice from "../Tab2/components/PurchasePrice";
import { getByDisplayValue } from "@testing-library/react";



const Tab3 = (props) => {
    const [directIncomeModal, setDirectIncomeModal] = useState(false);
    const [selfRentalModal, setSelfRentalModal] = useState(false);
    const [spouseRentalModal, setSpouseRentalModal] = useState(false);
    const [directIncomeGraphModal, setDirectIncomeGraphModal] = useState(false);
    const [indirectIncomeGraphModal, setIndirectIncomeGraphModal] = useState(false);
    const [totalDebtGraphModal, setTotalDebtGraphModal] = useState(false);
    const [targetedBackRatio,setTargetedBackRatio] = useState(null);

    const {
        directIncome,
        handleDirectIncomeInputChange,
        indirectIncome,
        handleIndirectIncomeInputChange,
        debtPayments,
        handleDebtPaymentsInputChange,
        handleMortgageDebtSave,
        handleDirectIncomeSave,
        handleIndirectIncomeSave,
        ratios,
        calculateRatios,
        handleTargetedRatioTab,
        indirectIncomeModal,
        handleIndirectIncomeModal,
        totalDebtModal,
        handleTotalDebtModal
    } = props;

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px) and (max-width: 1024px)'
      });
    useEffect(() => {
        if (
            !!indirectIncome?.totalIndirectIncome ||
            !!directIncome?.totalDirectIncome
        ) {
            calculateRatios();
        }
    }, [indirectIncome, directIncome, debtPayments,ratios?.calculatedBackRatio]);

    const handleDirectIncomeModal = () => {
        if(!directIncomeModal) {
			const payload = {
				"description":"View Direct Income"
			}
			insertLogs(payload)
		}
		setDirectIncomeModal(!directIncomeModal);
	};

    const handleDirectIncomeGraphModal = () => {
        // if(!directIncomeGraphModal) {
		// 	const payload = {
		// 		"description":"View Direct Income Graph"
		// 	}
		// 	insertLogs(payload)
		// }
		setDirectIncomeGraphModal(!directIncomeGraphModal);
	};

	

    const handleIndirectIncomeGraphModal = () => {
        // if(!directIncomeGraphModal) {
		// 	const payload = {
		// 		"description":"View Direct Income Graph"
		// 	}
		// 	insertLogs(payload)
		// }
		setIndirectIncomeGraphModal(!indirectIncomeGraphModal);
	};

    
    const handleTotalDebtGraphModal = () => {
        // if(!totalDebtModal) {
		// 	const payload = {
		// 		"description":"View Debt Payments"
		// 	}
		// 	insertLogs(payload)
		// }
		setTotalDebtGraphModal(!totalDebtGraphModal);
	};

    const handleSelfRentalModal = () => {
        if(!selfRentalModal) {
			const payload = {
				"description":"View Self Rental"
			}
			insertLogs(payload)
		}
        setSelfRentalModal(!selfRentalModal);
    }


    const handleSpouseRentalModal = () => {
        if(!selfRentalModal) {
			const payload = {
				"description":"View Spouse Rental"
			}
			insertLogs(payload)
		}
        setSpouseRentalModal(!spouseRentalModal);
    }


    const renderDirectIncomeModal = () => (
        <DirectIncomeModal
            directIncomeModal={directIncomeModal}
            handleDirectIncomeModal={handleDirectIncomeModal}
            directIncome={directIncome}
            handleDirectIncomeInputChange={handleDirectIncomeInputChange}
            handleDirectIncomeSave={handleDirectIncomeSave}
        />
    );

    const renderDirectIncomeGraphModal = () => (
        <DirectIncomeGraphModal
            directIncomeGraphModal={directIncomeGraphModal}
            handleDirectIncomeGraphModal={handleDirectIncomeGraphModal}
            directIncome={directIncome}
        />
    );

	const renderIndirectIncomeModal = () => (
        <IndirectIncomeModal
            indirectIncomeModal={indirectIncomeModal}
            handleIndirectIncomeModal={handleIndirectIncomeModal}
            indirectIncome={indirectIncome}
            handleIndirectIncomeInputChange={handleIndirectIncomeInputChange}
            selfRentalModal={selfRentalModal}
            handleSelfRentalModal={handleSelfRentalModal}
            handleIndirectIncomeSave={handleIndirectIncomeSave}
            handleSpouseRentalModal={handleSpouseRentalModal}
        />
    );

    const renderIndirectIncomeGraphModal = () => (
        <IndirectIncomeGraphModal
            indirectIncomeGraphModal={indirectIncomeGraphModal}
            handleIndirectIncomeGraphModal={handleIndirectIncomeGraphModal}
            indirectIncome={indirectIncome}
        />
    );

    const renderTotalDebtModal = () => (
        <TotalDebtModal
            totalDebtModal={totalDebtModal}
            handleTotalDebtModal={handleTotalDebtModal}
            debtPayments={debtPayments}
            handleDebtPaymentsInputChange={handleDebtPaymentsInputChange}
            handleMortgageDebtSave={handleMortgageDebtSave}
        />
    );


    const renderTotalDebtGraphModal = () => (
        <TotalDebtGraphModal
            totalDebtGraphModal={totalDebtGraphModal}
            handleTotalDebtGraphModal={handleTotalDebtGraphModal}
            debtPayments={debtPayments}
        />
    );

    const renderSelfRentalModal = () => (
        <SelfRental
            selfRentalModal={selfRentalModal}
            handleSelfRentalModal={handleSelfRentalModal}
            // handleTotalDebtModal={handleTotalDebtModal}
            // debtPayments={debtPayments}
            // handleDebtPaymentsInputChange={handleDebtPaymentsInputChange}
        />
    );

    const renderSpouseRentalModal = () => (
        <SpouseRental
            spouseRentalModal={spouseRentalModal}
            handleSpouseRentalModal={handleSpouseRentalModal}
            // handleTotalDebtModal={handleTotalDebtModal}
            // debtPayments={debtPayments}
            // handleDebtPaymentsInputChange={handleDebtPaymentsInputChange}
        />
    );

    const renderIncome = () => {
        return (
            <div className={`grid w-100  ${isDesktopOrLaptop ? "align-text" : "m-auto"} ${isTablet ? "pt-2" : ""}`}>
                <div className="col-8 md:col-12 lg:col-4">
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Direct Income</label>
                </div>
                <div className="col-10 md:col-12 lg:col-8">
                    <div className={`${isDesktopOrLaptop ? "field" : "w-122"}`}>
                            <div style={{display:"flex"}}>
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        prefix="$ "
                                        required={true}
                                        name="directIncome" locale="en-US"
                                        disabled={true}
                                        // onValueChange={
                                        //     handleIncomeCalculationInputChange
                                        // }
                                        value={
                                            directIncome?.totalDirectIncome
                                                ? (
                                                    directIncome?.totalDirectIncome
                                                )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-2 md:col-1 lg:col-1">
                                    <a
                                        href="javascript:;"
                                        className=""
                                        onClick={handleDirectIncomeModal}
                                    >
                                        <i className="pi pi-pencil" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a class="mr-4"
                            href="javascript:;"
                            onClick={handleDirectIncomeGraphModal}
                        >
                            View Graph
                        </a>
                </div>
                <div className="col-10 md:col-12 lg:col-4">
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Indirect Income</label>
                </div>
                <div className="col-10 md:col-12 lg:col-8">
                    <div className={`${isDesktopOrLaptop ? "field" : "w-122"}`}>
                        <div style={{display:"flex"}}>
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="indirectIncome" locale="en-US"
                                        disabled={true}
                                        prefix="$ "
                                        // onValueChange={
                                        //     handleIncomeCalculationInputChange
                                        // }
                                        value={
                                            indirectIncome?.totalIndirectIncome
                                                ? (
                                                    indirectIncome?.totalIndirectIncome
                                                )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-2 md:col-1 lg:col-1">
                                    <a
                                        href="javascript:;"
                                        className=""
                                        onClick={handleIndirectIncomeModal}
                                    >
                                        <i className="pi pi-pencil" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a class="mr-4"
                            href="javascript:;"
                            onClick={handleIndirectIncomeGraphModal}
                        >
                            View Graph
                        </a>
                </div>
            </div>
        );
    }

    const renderDebtPayments = () => {
        return (
            <div className={`grid w-100  ${isDesktopOrLaptop ? "align-text" : ""}`}>
                <div className="col-12 md:col-12 lg:col-4">
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}  ${isTablet ? "ml-6" : ""}`}>Total Debt Payments</label>
                </div>
                <div className={`col-10 md:col-12 lg:col-8 ${isDesktopOrLaptop ? "" : "totalDebtPayments-mt"} ${isTablet ? "ml-6" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : "w-122"}`}>
                        <div style={{display:"flex"}}>
                            <div className="p-inputgroup">
                            <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true} locale="en-US"
                                    name="totalDebtPayments"
                                    disabled={true}
                                    prefix="$ "
                                    // onValueChange={
                                    //     handleIncomeCalculationInputChange
                                    // }
                                    value={
                                        debtPayments?.totalDebtPayments
                                            ? (
                                                debtPayments?.totalDebtPayments
                                            )
                                            : 0
                                    }
                                />
                            </div>
                            <div className="col-2 md:col-1 lg:col-1">
                                <a
                                    href="javascript:;"
                                    className=" "
                                    onClick={handleTotalDebtModal}
                                >
                                    <i className="pi pi-pencil" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <a class="mr-4"
                            href="javascript:;"
                            onClick={handleTotalDebtGraphModal}
                        >
                            View Graph
                    </a>
                </div>
            </div>
        );
    }

    const renderRatios = () => {
        return (
            <div className={`relative ${isDesktopOrLaptop ? "grid w-100 align-text" : "col-6"}`}>
                <div className="col-12 md:col-12 lg:col-4">
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Front Ratio</label>
                </div>
                <div className="col-12 md:col-12 lg:col-7">
                    <div className="field">
                        <div className={`p-inputgroup ${isTablet ? "w-95" : ""}`}>
                        <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true} suffix=" %"
                                name="frontRatio"
                                disabled={true}
                                value={ratios?.calculatedFrontRatio}
                                // onValueChange={
                                //     handleIncomeCalculationInputChange
                                // }
                                // value={incomeCalculations?.selfIncome}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-4">
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Back Ratio</label>
                </div>
                <div className="col-12 md:col-12 lg:col-7">
                    <div className="field">
                        <div className={`p-inputgroup ${isTablet ? "w-95" : ""}`}>
                        <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true} suffix=" %"
                                name="backRatio"
                                disabled={true}
                                value={ratios?.calculatedBackRatio}
                                // onValueChange={
                                //     handleIncomeCalculationInputChange
                                // }
                                // value={incomeCalculations?.selfIncome}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderTargetedRatios = () => {
        return (
            <Fragment>
            <div className={`relative ${isDesktopOrLaptop ? "grid w-100 align-text" : "col-6"}`}>
                <div className={`col-12 md:col-12 lg:col-4 ${isTablet ? "ml-4" : ""}`}>
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Front Ratio</label>
                </div>
                <div className={`col-12 md:col-12 lg:col-7 ${isTablet ? "ml-4" : ""}`}>
                    <div className="field">
                        <div className={`p-inputgroup ${isTablet ? "w-95" : ""}`}>
                        <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true} suffix=" %"
                                name="targetedFrontRatio"
                                disabled={true}
                                value={ratios?.targetedFrontRatio}
                                // onValueChange={
                                //     handleIncomeCalculationInputChange
                                // }
                                // value={incomeCalculations?.selfIncome}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-12 md:col-12 lg:col-4 ${isTablet ? "ml-4" : ""}`}>
                    <label className={`${isDesktopOrLaptop ? "mt-3 lg:mr-0 md:mr-6" : ""}`}>Back Ratio</label>
                </div>
                <div className={`col-12 md:col-12 lg:col-7  ${isTablet ? "ml-4" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className={`p-inputgroup ${isTablet ? "w-95" : ""}`}>
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name=""
                                min={1}
                                max={99.99}
                                disabled={false}  suffix=" %"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                onValueChange={(e) => {
                                    setTargetedBackRatio(e.value)
                                }}
                                value={targetedBackRatio}
                            />
                        </div>
                    </div>
                </div>
               
            </div>
            <div className="col-12 md:col-12 lg:col-12 pt-0 align-text">
                <div className="mr-5">
                    <p style={{ fontWeight: "bold" }}>
                        To update Purchase Price based on Ratio
                    </p>
                    <Button
                        label="Calculate To Targeted"
                        disabled={!targetedBackRatio}
                        className="p-button-raised p-button-warning p-button-lg"
                        onClick={() =>
                            handleTargetedRatioTab({ targetedBackRatio })
                        }
                    />
                </div>
            </div>
         </Fragment>
        );
    }
    const header = () => {
        return (
            <div className={`grid header ${isDesktopOrLaptop ? "" : "m-auto"}`}>
                {isDesktopOrLaptop && (
                    <div className="col-12 md:col-2 lg:col-3">
                        {/* <label className="mt-3">Principal Paid</label> */}
                    </div>
                )}
                <div className= {`col-6 md:col-6 lg:col-4 ${isTablet ? "text-align-center" : ""}`}>
                    <h3>Income</h3>
                </div>
                <div className={`col-6 md:col-6 lg:col-4 ${isTablet ? "text-align-center" : ""}`}>
                    <h3>Debt Payments</h3>
                </div>
            </div>
        );
    };
    const headerRatios = () => {
        return (
            <div className={`grid header ${isDesktopOrLaptop ? "" : "m-auto"}`}>
                {isDesktopOrLaptop && (
                    <div className="col-12 md:col-2 lg:col-3">
                        {/* <label className="mt-3">Principal Paid</label> */}
                    </div>
                )}
                <div className= {`col-6 md:col-6 lg:col-4 ${isTablet ? "text-align-center" : ""}`}>
                    <h3>Ratios - Calculated</h3>
                </div>
                <div className={`col-6 md:col-6 lg:col-4 ${isTablet ? "text-align-center" : ""}`}>
                    <h3>Targeted</h3>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            
            <Panel
                header={`${isDesktopOrLaptop ? "" : "Mortgage Structure"}`}
                className="mb-2 MortgageStructure"
            >
                 {isDesktopOrLaptop ? (
                    <div className="">
                        <div className="grid w-100">
                            <div className="col-12 md:col-12 lg:col-6">
                                <div className="grid w-100">
                                    <div className="col-12 md:col-12 lg:col-4"></div>
                                    <div className="col-12 md:col-12 lg:col-8">
                                        <h3>Income</h3>
                                    </div>
                                </div>

                                {renderIncome()}
                            </div>
                            <div className="col-12 md:col-12 lg:col-6">
                                <div className="grid w-100">
                                    <div className="col-12 md:col-12 lg:col-4"></div>
                                    <div className="col-12 md:col-12 lg:col-8">
                                        <h3>Debt Payments</h3>
                                    </div>
                                </div>

                                {renderDebtPayments()}
                            </div>
                        </div>
                        <div className="grid w-100">
                            <div className="col-12 md:col-12 lg:col-6">
                                <div className="grid w-100">
                                    <div className="col-12 md:col-12 lg:col-4"></div>
                                    <div className="col-12 md:col-12 lg:col-8">
                                        <h3>Ratios - Calculated</h3>
                                    </div>
                                </div>

                                {renderRatios()}
                            </div>
                            <div className="col-12 md:col-12 lg:col-6">
                                <div className="grid w-100">
                                    <div className="col-12 md:col-12 lg:col-4"></div>
                                    <div className="col-12 md:col-12 lg:col-8">
                                        <h3>Targeted</h3>
                                    </div>
                                </div>

                                {renderTargetedRatios()}
                            </div>
                        </div>
                    </div>
                 ) : (
                    <Fragment>
                        {header()}
                        <div className={`${isDesktopOrLaptop ? "align-text" : "align-text-left"}`}>
                            <div className="w-90">
                                {renderIncome()}
                                {renderDebtPayments()}
                            </div>
                        </div>
                        {headerRatios()}
                        <div className={`${isDesktopOrLaptop ? "align-text" : "align-text-left"}`}>
                            <div class="grid w-95 margincls">
                                {renderRatios()}
                                {renderTargetedRatios()}
                            </div>
                        </div>
                    </Fragment>
                 )}
            </Panel>
            {renderDirectIncomeModal()}
            {renderIndirectIncomeModal()}
            {renderTotalDebtModal()}
            {renderSelfRentalModal()}
            {renderSpouseRentalModal()}
            {renderDirectIncomeGraphModal()}
            {renderIndirectIncomeGraphModal()}
            {renderTotalDebtGraphModal()}
        </Fragment>
    );
}

export default Tab3;