import { FormatAmount } from "app/utils/utility.service";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLeftCalculations, getLeftCalculations } from "../../../HomePurchaseStep3Slice";
import { useMediaQuery } from "react-responsive";

const { InputText } = require("primereact/inputtext");
const { Panel } = require("primereact/panel");

const PurchasePrice = ({handleLeftCalculations}) => {
    const dispatch = useDispatch();
    const leftContentValues = useSelector(getLeftCalculations);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px) and (max-width: 1024px)'
      });
    const headerTemplate = (options) => {
       const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1 flex-1`;
        return (
            <Fragment>
                
            </Fragment>
        );
    };
	 const pp = 	 
                <Fragment>
					<div className="col-12 md:col-11 lg:col-3">
                        <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Purchase Price</label>
                    </div>
                    <div className="col-12 md:col-11 lg:col-4">
                        <div className={`${isDesktopOrLaptop ? "field" : ""} ${isTablet ? "widthCls" : ""}`}>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true} mode="currency" currency="USD"
                                    locale="en-US"
                                    name="purchasePrice"
                                    disabled={false}
                                    min={0}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeLeftCalculations({
                                                name: "purchasePrice",
                                                value: e.value,
                                            })
                                        );
                                        handleLeftCalculations(
                                            "purchasePrice",
                                            e.value
                                        );
                                    }}
                                    value={leftContentValues?.purchasePrice}
                                />
                            </div>
                        </div>
                    </div>
				</Fragment>
    
    const dpp = 
                <Fragment>
                    <div className={`col-12 md:col-12 lg:col-3 ${isTablet ? "pl-0" : ""}`}>
                        <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Down Payment</label>
                    </div>
                    <div className={`col-12 md:col-11 lg:col-4 ${isTablet ? "pl-0" : ""}`}>
                        <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off" 
                                    required={true}
                                    name="downPaymentPercent"
                                    disabled={false} suffix=" %"
                                    min={3.5}
                                    max={100}
                                    maxFractionDigits={2}
                                    value={
                                        leftContentValues?.downPaymentPercent
                                    }
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeLeftCalculations({
                                                name: "downPaymentPercent",
                                                value: e.target.value,
                                            })
                                        );
                                        // if (e.target.value) {
                                        //     handleLeftCalculations(
                                        //         "downPaymentPercent",
                                        //         e.target.value
                                        //     );
                                        // }
                                        // handleLeftCalculations(
                                        //     "downPaymentPercent",
                                        //     e.target.value
                                        // );
                                    }}
                                    onBlur={(e) => {
                                        e.target.value = e.target.value.replace("%", "");
                                        if (
                                            !e.target.value ||
                                            e.target.value === "0"
                                        ) {
                                            dispatch(
                                                changeLeftCalculations({
                                                    name: "downPaymentPercent",
                                                    value: 3.5,
                                                })
                                            );
                                            handleLeftCalculations(
                                                "downPaymentPercent",
                                                3.5
                                            );
                                        } else {
                                            handleLeftCalculations(
                                                "downPaymentPercent",
                                                e.target.value
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>

    const dp = 
                <Fragment>
                    {!isDesktopOrLaptop && (
                        <div className="col-12 md:col-12 lg:col-3 margin-top-dp">                
                        </div>
                    )}
                    <div className={`col-12 md:col-11 lg:col-4 ${isTablet ? "pr-0 ml-5" : ""}`}>
                        <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                            <div className="p-inputgroup">
                                {/* <span
                                className="p-input"
                                style={{
                                    width: "100%",
                                }}
                            > */}
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off" mode="currency" currency="USD"
                                    required={true}
                                    name="downPayment"
                                    locale="en-US"
                                    disabled={false}
                                    // mode="decimal"
                                    // minFractionDigits={2}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeLeftCalculations({
                                                name: "downPayment",
                                                value: e.target.value,
                                            })
                                        );
                                        if (e.target.value) {
                                            handleLeftCalculations(
                                                "downPayment",
                                                e.target.value
                                            );
                                        } else {
                                            dispatch(
                                                changeLeftCalculations({
                                                    name: "downPayment",
                                                    value: 0,
                                                })
                                            );
                                            handleLeftCalculations(
                                                "downPayment",
                                                0
                                            );
                                        }
                                    }}
                                    // onBlur={(e) => {
                                    //     if (!e.target.value) {
                                    //         dispatch(
                                    //             changeLeftCalculations({
                                    //                 name: "downPayment",
                                    //                 value: 3.5,
                                    //             })
                                    //         );
                                    //         handleLeftCalculations(
                                    //             "downPayment",
                                    //             3.5
                                    //         );
                                    //     } else {
                                    //         handleLeftCalculations(
                                    //             "downPayment",
                                    //             e.target.value
                                    //         );
                                    //     }
                                    // }}
                                    value={leftContentValues?.downPayment}
                                // value={
                                //     leftContentValues?.downPayment
                                //         ? FormatAmount(
                                //               leftContentValues?.downPayment
                                //           )
                                //         : 0
                                // }
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
    return (
        <Panel
            className="mb-2 PurchasePrice"
            // header="Basic Information"
            headerTemplate={headerTemplate}
        >
            <div className={`${isDesktopOrLaptop ? "align-text" : "align-text-left"}`}>
                <div className="grid">
                    {isDesktopOrLaptop ? (
                        <Fragment>
                        {pp}
                        </Fragment>
                    ):
                    <div className={`w-100 ${isTablet ? "mt-2 ml-6" : ""}`}>
                        {pp}
                    </div>
                    }
                    
                </div>
			</div>
            <div className={`${isDesktopOrLaptop ? "align-text" : "align-text-left"}`}>
                <div className="grid">
                    {isDesktopOrLaptop ? (
                        <Fragment>
                        {dpp}
                        </Fragment>
                    ):
                    <div className={`${isTablet ? "w-50 mt-2" : ""}`}>
                        {dpp}
                    </div>
                    }
                    
                    {isDesktopOrLaptop ? (
                        <Fragment>
                        {dp}
                        </Fragment>
                    ):
                    <div className={`${isTablet ? "w-50 mt-2" : ""}`}>
                        {dp}
                    </div>
                    }
                </div>
			</div>
        </Panel>
    );
};

export default PurchasePrice;
