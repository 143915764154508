import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

const HomeOwnerShip = Loadable(lazy(() => import("./HomeOwnerShip/HomeOwnerShip")));
const HomeValueProjection = Loadable(lazy(() => import("./HomeValueProjection/HomeValueProjection")));


const reportsRoutes = [
    {
        path: "/reports/home-ownership",
        element: <HomeOwnerShip />,
    },
    {
        path: "/reports/home-value-projection",
        element: <HomeValueProjection />,
    },
];

export default reportsRoutes;
