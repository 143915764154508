import { Fragment } from "react";
import IncomeCalculationsFooter from "./common/IncomeCalculationsFooter";
import IncomeCalculationsHeader from "./common/IncomeCalculationsHeader";
import { useMediaQuery } from "react-responsive";

const { Dialog } = require("primereact/dialog");
const { InputNumber } = require("primereact/inputnumber");

const AppreciationModal = ({
    appreciationModal,
    incomeCalculations,
    handleIncomeCalculationInputChange,
    setAppreciationModal,
    handleCalculationSave
}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const yearsPropertyHeld = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Appreciation</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="yearsPropertyHeldBuy"
                                    disabled={true}
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="yearsPropertyHeldBuy"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.yearsPropertyHeldBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const yearsPropertyHeldSettings = () => {
        return (
            <Fragment>
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Years Property Held</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="yearsPropertyHeldBuy"
                                    disabled={true}
                                    prefix="$ "
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="yearsPropertyHeldSetting"
                                    disabled={false}
                                    minFractionDigits={0}
                                    min={0} max={100}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.yearsPropertyHeldSetting}
                                    maxFractionDigits={2}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Selling Cost</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="yearsPropertyHeldBuy"
                                    disabled={true}
                                    suffix=" %"
                                    min={0} max={100}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="sellingCostSetting"
                                    disabled={false}
                                    suffix=" %"
                                    min={0} max={100}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.sellingCostSetting}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Fragment>
        );
    };

    const sellingCosts = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Selling Costs</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="sellingCostBuy"
                                    disabled={true}
                                    prefix="$ "
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="sellingCostBuy"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.sellingCostBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const netRealization = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Net Realization</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="netRealizationBuy"
                                    prefix="$ "
                                    disabled={true}
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="netRealizationBuy"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.netRealizationBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const returnPerAnnum = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Return Per Annum</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="returnPerAnnumBuy"
                                    disabled={true}
                                    prefix="$ "
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="returnPerAnnumBuy"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.returnPerAnnumBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Appreciation"
            position="top"
            visible={appreciationModal}
            onHide={() => setAppreciationModal(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                <IncomeCalculationsHeader />
                {yearsPropertyHeldSettings()}
                {yearsPropertyHeld()}
                {sellingCosts()}
                {netRealization()}
                {returnPerAnnum()}
                <IncomeCalculationsFooter
                    handleCalculationSave={handleCalculationSave}
                    setModal={setAppreciationModal}
                />
            </div>
        </Dialog>
    );
};

export default AppreciationModal;