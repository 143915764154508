import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useMediaQuery } from "react-responsive";

const TotalDebtModal = ({
    totalDebtModal,
    handleTotalDebtModal,
    debtPayments,
    handleDebtPaymentsInputChange,
    handleMortgageDebtSave
}) => {
    const onHide = () => {
        handleTotalDebtModal(false);
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"}`}>
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-raised p-button p-button-lg"
                                onClick={() => onHide()}
                            />
                            <Button
                                label="Save"
                                className="p-button-raised p-button-warning p-button-lg"
                                onClick={handleMortgageDebtSave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAutoLoan = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Auto Loan</label>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfAutoLoan"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.selfAutoLoan}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseAutoLoan"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.spouseAutoLoan}
                            />
                        </div>
                    </div>
                </div>   
            </div>
        );
    };

    const renderStudentLoan = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Student Loan</label>
                </div>
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfStudentLoan"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.selfStudentLoan}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseStudentLoan"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.spouseStudentLoan}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderCreditCards = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Credit Cards</label>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfCreditCards"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.selfCreditCards}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseCreditCards"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.spouseCreditCards}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMortgage = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Mortgage</label>
                </div>
               <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfMortgage"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.selfMortgage}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseMortgage"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.spouseMortgage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOther = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Others</label>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfOther"
                                prefix="$ "
                                disabled={false}
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.selfOther}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseOther"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.spouseOther}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTotalDebtPayments = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Total Debt Payments</label>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="selfTotalDebtPayments"
                                disabled={true}
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.selfTotalDebtPayments}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 md:col-4 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseTotalDebtPayments"
                                disabled={true}
                                prefix="$ "
                                onValueChange={handleDebtPaymentsInputChange}
                                value={debtPayments?.spouseTotalDebtPayments}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Monthly Debt Payments"
            visible={totalDebtModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={renderFooter()}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3"></div>
                <div className="col-6 md:col-3 lg:col-3">
                    <h4>Self</h4>
                </div>
                <div className="col-6 md:col-2 lg:col-4">
                    <h4>Spouse</h4>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "align-text" : ""}`}>
                {renderAutoLoan()}
                {renderStudentLoan()}
                {renderCreditCards()}
                {renderMortgage()}
                {renderOther()}
                {renderTotalDebtPayments()}
            </div>
        </Dialog>
    );
};

export default TotalDebtModal;