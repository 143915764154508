import { FormatAmount } from "app/utils/utility.service";
import { getMonthlyExpensesCalculations } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import MortgageStructure from "./MortgageStructure/MortgageStructure";
import TaxBreak from "./TaxBreak/TaxBreak";
import { useMediaQuery } from "react-responsive";

const MonthlyExpense = ({
    monthlyExpenseModal,
    handleMonthlyExpenseModal,
    handleExpensesSave,
    mortgageStructureModal,
	handleMortgageStructureModal,
    taxBreakModal,
    handleTaxBreakModal
}) => {
    const monthlyExpenseValues = useSelector(getMonthlyExpensesCalculations);
   
    const renderMortgageStructureModal = () => (
        <MortgageStructure
            mortgageStructureModal={mortgageStructureModal}
            handleMortgageStructureModal={handleMortgageStructureModal}
            handleExpensesSave={handleExpensesSave}
        />
    );
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const prefix = '$ '
    const renderTaxBreakModal = () => (
        <TaxBreak
            taxBreakModal={taxBreakModal}
            handleTaxBreakModal={handleTaxBreakModal}
            handleExpensesSave={handleExpensesSave}
        />
    );

    const onHide = () => {
        handleMonthlyExpenseModal(false);
    };
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"}`}>
                        <Button
                            label="Close"
                            className="p-button-raised p-button-warning p-button-lg min-width"
                            onClick={() => onHide()}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Dialog
                header="Monthly Expenses"
                position="top"
                visible={monthlyExpenseModal}
                onHide={() => onHide()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw" }}
                footer={renderFooter()}
                className="MonthlyExpensesModal"
                closeOnEscape={false}
                draggable={false}
            >
                <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                    {/* Mortgage Payments */}
                    <div className="grid">
                        <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                            <label className="mt-3">Mortgage Payments</label>
                        </div>
                        <div className={`${isDesktopOrLaptop ? "col-8" : "col-12"}`}>
                            <div className="field">
                                <div style={{display:"flex"}}>
                                    <div className="p-inputgroup">
                                        <InputText
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={true}
                                            mode="currency" currency="USD"
                                            locale="en-US"
                                            name="mortgagePayments"
                                            disabled={true}
                                            value={
                                                monthlyExpenseValues?.mortgagePayment
                                                    ? FormatAmount(
                                                        prefix+monthlyExpenseValues?.mortgagePayment
                                                    )
                                                    : 0
                                            }
                                        />
                                    </div>
                                    <div className="col-1 md:col-1 lg:col-1">
                                        <a
                                        href="javascript:;"
                                        className=""
                                        onClick={handleMortgageStructureModal}
                                        >
                                            <i className="pi pi-pencil" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>

                    {/* Annual Expenses */}
                    <div className="grid">
                        <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                            <label className="mt-2">
                                Tax Break Comes As
                                <br />
                                Increased Paycheck
                            </label>
                        </div>
                        <div className={`${isDesktopOrLaptop ? "col-8" : "col-12"}`}>
                            <div className="field">
                                <div style={{display:"flex"}}>
                                    <div className="p-inputgroup">

                                        <InputText
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={true}
                                            name="totalTaxBreak"
                                            disabled={true}
                                            mode="currency" currency="USD"
                                            locale="en-US"
                                            value={
                                                monthlyExpenseValues?.totalTaxBreak
                                                    ? FormatAmount(
                                                        prefix+monthlyExpenseValues?.totalTaxBreak
                                                    )
                                                    : 0
                                            }
                                        />
                                    </div>
                                    <div className="col-1 md:col-1 lg:col-1">
                                        <a
                                        href="javascript:;"
                                        className=""
                                        onClick={handleTaxBreakModal}
                                        >
                                            <i className="pi pi-pencil" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Principal Repaid */}
                    <div className="grid">
                        <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                            <label className="mt-3">Principal Repaid</label>
                        </div>
                        <div className={`${isDesktopOrLaptop ? "col-8" : "col-12"}`}>
                            <div className="field">
                                <div className="p-inputgroup">

                                    <InputText
                                        className="vw-input-nfl"
                                        autoComplete="off"
                                        required={true}
                                        name="principalRepaid"
                                        disabled={true}
                                        mode="currency" currency="USD"
                                        locale="en-US"
                                        value={
                                            monthlyExpenseValues?.principalRepaid
                                                ? FormatAmount(
                                                    prefix+monthlyExpenseValues?.principalRepaid
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Interest on Equity */}
                    <div className="grid">
                        <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                            <label className="mt-3">Interest on Equity</label>
                        </div>
                        <div className={`${isDesktopOrLaptop ? "col-8" : "col-12"}`}>
                            <div className="field">
                                <div className="p-inputgroup">

                                    <InputText
                                        className="vw-input-nfl"
                                        autoComplete="off"
                                        required={true}
                                        name="interestOnEquity"
                                        disabled={true}
                                        mode="currency" currency="USD"
                                        locale="en-US"
                                        value={
                                            monthlyExpenseValues?.interestOnEquity
                                                ? FormatAmount(
                                                    prefix+monthlyExpenseValues?.interestOnEquity
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Net Expense */}
                    <div className="grid">
                        <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                            <label className="mt-3">Net Expense</label>
                        </div>
                        <div className={`${isDesktopOrLaptop ? "col-8" : "col-12"}`}>
                            <div className="field">
                                <div className="p-inputgroup">

                                    <InputText
                                        className="vw-input-nfl"
                                        autoComplete="off"
                                        required={true}
                                        name="netExpense"
                                        disabled={true}
                                        mode="currency" currency="USD"
                                    locale="en-US"
                                        value={
                                            monthlyExpenseValues?.netExpense
                                                ? FormatAmount(
                                                    prefix+monthlyExpenseValues?.netExpense
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            {renderMortgageStructureModal()}
            {renderTaxBreakModal()}
        </Fragment>
    );
};

export default MonthlyExpense;
