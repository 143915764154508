import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import { FormatAmount } from 'app/utils/utility.service';


const TotalDebtGraphModal = ({
    totalDebtGraphModal,
    handleTotalDebtGraphModal,
    debtPayments,
}) => {
    const onHide = () => {
        handleTotalDebtGraphModal(false);
    };

    

    const [basicData, setBasicData] = useState({
        labels: ["Auto Loan", "Student Loan", "Credit Cards", "Mortgage", "Other"],
        datasets: [
            {
                label: `Self - ${
                    debtPayments?.selfTotalDebtPayments
                        ? FormatAmount(debtPayments?.selfTotalDebtPayments)
                        : 0
                }`,
                backgroundColor: "#42A5F5",
                data: [
                    debtPayments?.selfAutoLoan,
                    debtPayments?.selfStudentLoan,
                    debtPayments?.selfCreditCards,
                    debtPayments?.selfMortgage,
                    debtPayments?.selfOther,
                ],
            },
            {
                label: `Spouse - ${
                    debtPayments?.spouseTotalDebtPayments
                        ? FormatAmount(debtPayments?.spouseTotalDebtPayments)
                        : 0
                }`,
                backgroundColor: "#FFA726",
                data: [
                    debtPayments?.spouseAutoLoan,
                    debtPayments?.spouseStudentLoan,
                    debtPayments?.spouseCreditCards,
                    debtPayments?.spouseMortgage,
                    debtPayments?.spouseOther,
                ],
            },
        ],
    });

    useEffect(()=>{
        setBasicData((prev)=>({
            ...prev,
            datasets: [
                {
                    ...prev.datasets[0],
                    label: `Self - ${
                        debtPayments?.selfTotalDebtPayments
                            ? FormatAmount(debtPayments?.selfTotalDebtPayments)
                            : 0
                    }`,
                    data: [
                        debtPayments?.selfAutoLoan,
                        debtPayments?.selfStudentLoan,
                        debtPayments?.selfCreditCards,
                        debtPayments?.selfMortgage,
                        debtPayments?.selfOther,
                    ],
                },
                {
                    ...prev.datasets[1],
                    label: `Spouse - ${
                        debtPayments?.spouseTotalDebtPayments
                            ? FormatAmount(debtPayments?.spouseTotalDebtPayments)
                            : 0
                    }`,
                    data: [
                        debtPayments?.spouseAutoLoan,
                        debtPayments?.spouseStudentLoan,
                        debtPayments?.spouseCreditCards,
                        debtPayments?.spouseMortgage,
                        debtPayments?.spouseOther,
                    ],
                }
            ]
        }))
    },[totalDebtGraphModal])

    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
            y: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
        },
    };

    

    return (
        <Dialog
            header="Monthly Debt Payments Graph"
            visible={totalDebtGraphModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw" }}
            // footer={renderFooter()}
            className="MonthlyExpenseModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="align-text">
                <Chart type="bar" data={basicData} options={basicOptions} />
            </div>
        </Dialog>
    );
};

export default TotalDebtGraphModal