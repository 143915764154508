import { FIRST_LOAN_RATE } from "app/utils/Constants";
import { FormatAmount } from "app/utils/utility.service";
import { changeMonthlyMortgageExpenses } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { getMortgageStructureExpensesCalculations } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const MortgageStructure = ({
	mortgageStructureModal,
	handleMortgageStructureModal,
	handleExpensesSave
}) => {
	const mortgageMonthlyExpenses = useSelector(getMortgageStructureExpensesCalculations);
	const dispatch = useDispatch();
	
    const dollar = '$ ';
	const onHide = () => {
		handleMortgageStructureModal(false);
	};
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
	const renderFooter = () => {
		return (
			<div>
				<div className="grid">
					<div className="col-2"></div>
					<div className={`${isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"}`}>
						<div className={`${isDesktopOrLaptop ? "ml" : ""}`}>
							<Button
								label="Cancel"
								className="p-button-raised p-button p-button-lg"
								onClick={() => onHide()}
							/>
							<Button
								label="Save"
								className={`p-button-raised p-button-warning p-button-lg ${isDesktopOrLaptop ? "" : "ml-5"}`}
								onClick={handleExpensesSave}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderHeader = () => {
		return (
			<p style={{ color: "#333", marginBottom: 0 }}>
				Depending on buyers down payment availability, they can choose
				just one Mortgage with high down payment OR they can choose one
				Mortgage with low down payment resulting in additional insurance
				cost called PMI OR they can choose a two Mortgage structure
				which may allow avoiding the PMI and still permit low down
				payment.
			</p>
		);
	};

	const renderFirstLoan = () => (
        <Fragment>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">First Loan</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-nfl-border"
                                autoComplete="off"
                                required={true}
                                name="firstLoanPercent"
                                disabled={true}
                                id="firstLoanPercent"
                                suffix=" %"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={
                                    mortgageMonthlyExpenses?.firstLoanPercent
                                        ? mortgageMonthlyExpenses?.firstLoanPercent
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">Principal</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl-border"
                                autoComplete="off"
                                required={true}
                                name="firstLoanPrincipal"
                                disabled={true}
                                value={
                                    mortgageMonthlyExpenses?.firstLoanPrincipalAmt
                                        ? dollar+FormatAmount(
                                              mortgageMonthlyExpenses?.firstLoanPrincipalAmt
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">First (Rate)</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true}
                                name="firstLoanInterestRate"
                                disabled={false}
                                min={1.000}
                                max={99.999}
                                suffix=" %"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                useGrouping={false}
                                value={
                                    mortgageMonthlyExpenses?.firstLoanInterestRate
                                }
                                // onValueChange={(e) => {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "firstLoanInterestRate",
                                //             value: e.target.value,
                                //         })
                                //     );
                                // }}
                                // onBlur={(e) => {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "firstLoanInterestRate",
                                //             value:
                                //                 !!e.target.value === false
                                //                     ? FIRST_LOAN_RATE
                                //                     : Number(e.target.value),
                                //         })
                                //     );
                                // }}

                                onBlur={(e) => {
                                    e.target.value = e.target.value.replace("%", "");
                                    if (!e.target.value) {
                                        dispatch(
                                            changeMonthlyMortgageExpenses({
                                                name: "firstLoanInterestRate",
                                                value: FIRST_LOAN_RATE,
                                            })
                                        );
                                    } else {
                                        let interestRate = e.target.value;
                                        if (interestRate <= 0) {
                                            alert(
                                                "Interest Rate must be greater than 0"
                                            );

                                            dispatch(
                                                changeMonthlyMortgageExpenses({
                                                    name: "firstLoanInterestRate",
                                                    value: 1.0,
                                                })
                                            );
                                            return;
                                        }
                                        if (interestRate > 99.999) {
                                            alert(
                                                "Interest Rate must be lesser than 100"
                                            );
                                            // dispatch(
                                            //     changeMonthlyMortgageExpenses({
                                            //         name: "firstLoanInterestRate",
                                            //         value: 0,
                                            //     })
                                            // );
                                            return;
                                        } else {
                                            dispatch(
                                                changeMonthlyMortgageExpenses({
                                                    name: "firstLoanInterestRate",
                                                    value: interestRate,
                                                })
                                            );
                                        }
                                    }
                                }}
                                onValueChange={(e) => {
                                    dispatch(
                                        changeMonthlyMortgageExpenses({
                                            name: "firstLoanInterestRate",
                                            value: e.target.value,
                                        })
                                    );
                                    // if(!e.target.value) {
                                    //     dispatch(
                                    //         changeMonthlyMortgageExpenses({
                                    //             name: "firstLoanInterestRate",
                                    //             value: FIRST_LOAN_RATE,
                                    //         })
                                    //     );
                                    // } else {
                                    //     console.log(e.target.value);
                                    //     if(e.target.value > 99) {
                                    //         alert("Interest Rate must be lesser than 100");
                                    //         dispatch(
                                    //             changeMonthlyMortgageExpenses({
                                    //                 name: "firstLoanInterestRate",
                                    //                 value: FIRST_LOAN_RATE,
                                    //             })
                                    //         );
                                    //     } else {
                                    //         dispatch(
                                    //             changeMonthlyMortgageExpenses({
                                    //                 name: "firstLoanInterestRate",
                                    //                 value: e.target.value,
                                    //             })
                                    //         );
                                    //     }
                                    // }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">Payment</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="rent"
                                mode="currency" currency="USD"
                                locale="en-US"
                                disabled={true}
                                value={
                                    mortgageMonthlyExpenses?.firstPayment
                                        ? dollar+FormatAmount(
                                              mortgageMonthlyExpenses?.firstPayment
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-2">Total Mortgage Payment</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl-border"
                                autoComplete="off"
                                required={true}
                                name="firstPayment"
                                disabled={true}
                                mode="currency" currency="USD"
                                locale="en-US"
                                value={
                                    mortgageMonthlyExpenses?.firstPayment
                                        ? dollar+FormatAmount(
                                              mortgageMonthlyExpenses?.firstPayment
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-2">PMI Percentage</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    name="pmiPercentage"
                                    suffix=" %"
                                    minFractionDigits={2}
                                    disabled={false}
                                    min={0} max={100}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeMonthlyMortgageExpenses({
                                                name: "pmiPercentage",
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                    value={
                                        mortgageMonthlyExpenses?.pmiPercentage
                                            ? mortgageMonthlyExpenses?.pmiPercentage
                                            : 0
                                    }
                                    maxFractionDigits={2}
                                />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-2">PMI</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl-border"
                                autoComplete="off"
                                required={true}
                                name="firstPayment"
                                disabled={true}
                                mode="currency" currency="USD"
                                locale="en-US"
                                value={
                                    mortgageMonthlyExpenses?.pmiValue
                                        ? dollar+FormatAmount(
                                              mortgageMonthlyExpenses?.pmiValue
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">HOA</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true}
                                name="hoa"
                                disabled={false}
                                mode="currency" currency="USD"
                                locale="en-US"
                                value={
                                    mortgageMonthlyExpenses?.hoa
                                        ? mortgageMonthlyExpenses?.hoa
                                        : ""
                                }
                                onValueChange={(e) => {
                                    dispatch(
                                        changeMonthlyMortgageExpenses({
                                            name: "hoa",
                                            value: e.target.value,
                                        })
                                    );
                                }}
                                // onBlur={(e) => {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "hoa",
                                //             value:
                                //                 !!e.target.value === false
                                //                     ? 0
                                //                     : Number(e.target.value),
                                //         })
                                //     );
                                // }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-2">Total Monthly Payment</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl-border"
                                autoComplete="off"
                                required={true}
                                mode="currency" currency="USD"
                                locale="en-US"
                                name="firstTotalMonthly"
                                disabled={true}
                                value={
                                    mortgageMonthlyExpenses?.firstTotalMonthly
                                        ? dollar+FormatAmount(
                                              mortgageMonthlyExpenses?.firstTotalMonthly
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

	const renderSecondLoan = () => (
        <Fragment>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">Second Loan</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                           <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true}
                                name="secondLoanPercent"
                                disabled={false}
                                min={0}
                                max={99.999}
                                suffix=" %"
                                id="secondLoanPercent"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                allowEmpty={true}
                                value={
                                    mortgageMonthlyExpenses?.secondLoanPercent
                                        ? mortgageMonthlyExpenses?.secondLoanPercent
                                        : 0
                                }
                                // onValueChange={(e) => {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "firstLoanInterestRate",
                                //             value: e.target.value,
                                //         })
                                //     );
                                // }}
                                // onBlur={(e) => {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "firstLoanInterestRate",
                                //             value:
                                //                 !!e.target.value === false
                                //                     ? FIRST_LOAN_RATE
                                //                     : Number(e.target.value),
                                //         })
                                //     );
                                // }}

                                onBlur={(e) => {
                                    // coso
                                    // if (!e.target.value) {
                                    //     dispatch(
                                    //         changeMonthlyMortgageExpenses({
                                    //             name: "secondLoanInterestRate",
                                    //             value: 0,
                                    //         })
                                    //     );
                                    // } else {
                                    let secondLoanPercent = e.target.value.replace("%", "");
                                    //let secondLoanPercent = e.target.value;

                                    if (
                                        Number(
                                            mortgageMonthlyExpenses.firstLoanPercent
                                        ) +
                                            Number(secondLoanPercent) >
                                        100
                                    ) {
                                        alert(
                                            "First and SecondInterest Rate cannot be greater than 100"
                                        );
                                        e.target.value = 0;

                                        // dispatch(
                                        //     changeMonthlyMortgageExpenses({
                                        //         name: "secondLoanPercent",
                                        //         value: "0",
                                        //     })
                                        // );
                                        return;
                                    } else if (secondLoanPercent > 99.999) {
                                        alert(
                                            "Second Loan Interest Rate must be lesser than 100"
                                        );
                                        e.target.value = 0;
                                        // dispatch(
                                        //     changeMonthlyMortgageExpenses({
                                        //         name: "firstLoanInterestRate",
                                        //         value: 0,
                                        //     })
                                        // );
                                        return;
                                    } else {
                                        dispatch(
                                            changeMonthlyMortgageExpenses({
                                                name: "secondLoanPercent",
                                                value: secondLoanPercent,
                                            })
                                        );
                                    }
                                    // }
                                }}
                                // onValueChange={(e) => {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "secondLoanPercent",
                                //             value: e.target.value,
                                //         })
                                //     );
                                // }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "grid" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">Principal</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl-border"
                                autoComplete="off"
                                required={true}
                                name="secondLoanPrincipalAmt"
                                disabled={true}
                                mode="currency" currency="USD"
                                locale="en-US"
                                value={
                                    mortgageMonthlyExpenses?.secondLoanPrincipalAmt
                                        ? FormatAmount(
                                              mortgageMonthlyExpenses?.secondLoanPrincipalAmt
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "grid mb-5" : ""}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-3">Second (Rate)</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true}
                                name="secondLoanInterestRate"
                                disabled={false}
                                suffix=" %"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                min={0}
                                max={99.999}
                                useGrouping={false}
                                value={
                                    mortgageMonthlyExpenses?.secondLoanInterestRate
                                }
                                onValueChange={(e) => {
                                    dispatch(
                                        changeMonthlyMortgageExpenses({
                                            name: "secondLoanInterestRate",
                                            value: e.target.value,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="grid">
				<div className="col-3">
					<label>Payment</label>
				</div>
				<div className="col-7">
					<div className="field">
						<span
							className="p-input-icon-left"
							style={{
								width: "100%",
							}}
						>
							<i className="pi pi-dollar" />
							<InputText
								className="vw-input-nfl text-right"
								autoComplete="off"
								required={true}
								name="rent"
								disabled={true}
							/>
						</span>
					</div>
				</div>
			</div> */}

            <div className={`${isDesktopOrLaptop ? "grid mt-8" : "margin-top-mortgage"}`}>
                <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                    <label className="mt-2">Total Mortgage Payment</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="secondPayment"
                                disabled={true}
                                mode="currency" currency="USD"
                                locale="en-US"
                                value={
                                    mortgageMonthlyExpenses?.secondPayment
                                        ? FormatAmount(
                                              mortgageMonthlyExpenses?.secondPayment
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

	return (
		<Dialog
			header="Mortgage Structure"
			visible={mortgageStructureModal}
			onHide={() => onHide()}
			position="top"
			breakpoints={{ "960px": "90vw" }}
			style={{ width: "70vw" }}
			footer={renderFooter()}
			className="MortgageStructureModal"
			closeOnEscape={false}
			draggable={false}
		>
             {isDesktopOrLaptop ? (
                <Fragment>
			    {renderHeader()}
                </Fragment>
             ): (
                null
             )}
			<div className={`${isDesktopOrLaptop ? "align-text" : ""}`}>
				<div className="grid ipadGrid">
					<div className="col">
						<div className="text-center">
							<h4>First Loan</h4>
						</div>
						{renderFirstLoan()}
					</div>
					<div className="col">
						<div className="text-center">
							<h4>Second Loan</h4>
						</div>
						{renderSecondLoan()}
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default MortgageStructure;
