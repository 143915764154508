import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const Clients= Loadable(lazy(() => import("./Clients")));
const AddClient= Loadable(lazy(() => import("./AddClient/AddClient")));
const NewClients= Loadable(lazy(() => import("./NewClients/NewClients")));

// const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
// const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const clientRoutes = [
    {
        path: "/clients",
        element: <Clients />,
    },
    {
        path: "/clients/new",
        element: <NewClients />,
    },
    {
        path: "/clients/add-new",
        element: <AddClient />,
    },
];

export default clientRoutes;
