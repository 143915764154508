import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const Agents= Loadable(lazy(() => import("./Agents")));
const AddAgent= Loadable(lazy(() => import("./AddAgent/AddAgent")));

// const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
// const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const agentRoutes = [
    {
        path: "/agents",
        element: <Agents />,
    },
    {
        path: "/agents/add-new",
        element: <AddAgent />,
    },
];

export default agentRoutes;
