const { createSlice } = require("@reduxjs/toolkit");

const rentalObject = {
    nett: 0,
    rent: 0,
    pi: 0,
    t: 0,
    i: 0,
    balance: 0,
    intt: 0,
    purchase: 0,
    propertyTax:1.25
};

const initialState = {
    leftCalculations: null,
    monthlyExpenses: null,
    expenses: null,
    monthlyExpensesMortgageStructure: null,
    monthlyDeductibleExpense: null,
    annualExpense: null,
    modalList: null,
    indirectRentalIncome: {
        selfRentalTotal: 0,
        spouseRentalTotal: 0,
        selfRentalList: [
            {
                ...rentalObject,
            },
            {
                ...rentalObject,
            },
            {
                ...rentalObject,
            },
        ],
        spouseRentalList: [
            {
                ...rentalObject,
            },
            {
                ...rentalObject,
            },
            {
                ...rentalObject,
            },
        ],
    },
};

const HomePurchaseStep3Slice = createSlice({
    name: "homePurchaseStep3",
    initialState,
    reducers: {
        storeLeftCalculations: (state, action) => {
            state.leftCalculations = action.payload;
        },
        changeLeftCalculations: (state, action) => {
            const { name, value } = action.payload;
            state.leftCalculations[name] = value;
        },
        storeExpenses: (state, action) => {
            state.expenses = action.payload;

        },
        storeMonthlyExpenses: (state, action) => {
            state.monthlyExpenses = action.payload;
        },
        changeMonthlyExpenses: (state, action) => {
            const { name, value } = action.payload;
            state.monthlyExpenses[name] = value;
        },
        storeMonthlyExpensesMortgageStructure: (state, action) => {
            state.monthlyExpensesMortgageStructure = action.payload; 
        },
        storeMonthlyDeductibleExpenses: (state, action) => {
            state.monthlyDeductibleExpense = action.payload; 
        },
        changeMonthlyDeductibleExpenses: (state, action) => {
            const { name, value } = action.payload;
            state.monthlyDeductibleExpense[name] = value; 
        },
        storeAnnualExpense: (state, action) => {
            state.annualExpense = action.payload;
        },
        changeAnnualExpenses: (state, action) => {
            const { name, value } = action.payload;
            state.annualExpense[name] = value;
        },
        changeMonthlyMortgageExpenses: (state, action) => {
            const { name, value } = action.payload;
            state.monthlyExpensesMortgageStructure[name] = value;
        },
        handleModalList: (state, action) => {
            const {name, value} = action.payload;
            state.modalList[name] = !value;
        },
        onAddRental: (state, action) => {
            const { type } = action.payload;
            if(type === "Self") {
                let selfRental = [...state.indirectRentalIncome.selfRentalList];
                selfRental.push(rentalObject);
                state.indirectRentalIncome.selfRentalList = selfRental;
            } else {
                let spouseRental = [...state.indirectRentalIncome.spouseRentalList];
                spouseRental.push(rentalObject);
                state.indirectRentalIncome.spouseRentalList = spouseRental;
            }
        },
        onRemoveRental: (state, action) => {
            const { type, index } = action.payload;
            if(type === "Self") {
                let selfRental = [...state.indirectRentalIncome.selfRentalList]; 
                selfRental.splice(index,1);
                state.indirectRentalIncome.selfRentalList = selfRental;
            } else {
                let spouseRental = [...state.indirectRentalIncome.spouseRentalList]; 
                spouseRental.splice(index,1);
                state.indirectRentalIncome.spouseRentalList = spouseRental;
            }
        },
        storeSelfRentalList:(state, action) => {
            state.indirectRentalIncome.selfRentalList = action.payload;
        },
        storeSpouseRentalList:(state, action) => {
            state.indirectRentalIncome.spouseRentalList = action.payload;
        },
        storeRental: (state, action) => {
            const {type, index, payload} = action.payload;
            if(type === "Self") {
                let selfRentalObject = state.indirectRentalIncome.selfRentalList[index];
                selfRentalObject = payload;
                state.indirectRentalIncome.selfRentalList[index] = selfRentalObject;
            } else {
                let spouseRentalObject = state.indirectRentalIncome.spouseRentalList[index];
                spouseRentalObject = payload;
                state.indirectRentalIncome.spouseRentalList[index] = spouseRentalObject;
            }
        },
        storeIndirectIncomeTotalRental: (state, action) => {
            const {type, total} = action.payload;
            if(type === "Self") {
               state.indirectRentalIncome.selfRentalTotal = total;
            } else {
                state.indirectRentalIncome.spouseRentalTotal = total;
            }
        },
        clearHomePurchaseValues: (state) => {
            state.leftCalculations = initialState.leftCalculations;
            state.monthlyExpenses = initialState.monthlyExpenses;
            state.monthlyExpensesMortgageStructure =
                initialState.monthlyExpensesMortgageStructure;
            state.monthlyDeductibleExpense = initialState.monthlyDeductibleExpense;
            state.annualExpense = initialState.annualExpense;
            state.modalList = initialState.modalList;
            state.indirectRentalIncome = initialState.indirectRentalIncome;
        }

    },
});

const { actions } = HomePurchaseStep3Slice;

export const {
    storeLeftCalculations,
    changeLeftCalculations,
    storeMonthlyExpenses,
    changeMonthlyExpenses,
    storeMonthlyExpensesMortgageStructure,
    storeMonthlyDeductibleExpenses,
    storeAnnualExpense,
    changeAnnualExpenses,
    changeMonthlyMortgageExpenses,
    handleModalList,
    onAddRental,
    onRemoveRental,
    storeRental,
    storeIndirectIncomeTotalRental,
    storeSelfRentalList,
    storeSpouseRentalList,
    clearHomePurchaseValues,
    storeExpenses,
    changeMonthlyDeductibleExpenses
} = actions;

export const getLeftCalculations = (state) =>
    state.homePurchaseStep3.leftCalculations;

export const getMonthlyExpensesCalculations = (state) =>
    state.homePurchaseStep3.monthlyExpenses;

export const getMortgageStructureExpensesCalculations = (state) =>
    state.homePurchaseStep3.monthlyExpensesMortgageStructure;

export const getDeductibleExpenseCalculation = (state) =>
    state.homePurchaseStep3.monthlyDeductibleExpense;

export const getAnnualExpenseCalculation = (state) =>
        state.homePurchaseStep3.annualExpense;

export const getModalList = (state) => state.homePurchaseStep3.modalList;

export const getSelfRentalList = (state) => state.homePurchaseStep3.indirectRentalIncome.selfRentalList;

export const getSpouseRentalList = (state) => state.homePurchaseStep3.indirectRentalIncome.spouseRentalList;

export const getIndirectRentalIncome = (state) => state.homePurchaseStep3.indirectRentalIncome;

export const getExpenses = (state) => state.homePurchaseStep3.expenses;

export default HomePurchaseStep3Slice.reducer;
