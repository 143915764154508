import { Panel } from "primereact/panel";
import { useState } from "react";
import { ASSUMPTION_CONSTANTS } from "../investment_constants";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const Ratios = ({handleRatiosInputChange, ratiosInput}) => {

    
    
    // const renderFooter = () => {
    //     return (
    //         <div>
    //             <div className="grid">
    //                 <div className="col-6">
    //                     <Button
    //                         label="Save"
    //                         className="p-button-raised p-button-warning p-button-lg"
    //                         onClick={() => handleAssumptionsSave()}
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    return (
        <Panel className="Ratios">
                <div className={`mb-2`}>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4 mt-3">
                            <label className="">
                                Cap Rate
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="ratios_caprate"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0} max={100}
                                        disabled={true}
                                        onValueChange={
                                            handleRatiosInputChange
                                        }
                                        value={ratiosInput?.ratios_caprate}
                                        // value={leftContentratiosInput?.purchasePrice ? FormatAmount(leftContentratiosInput?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">
                                Gross Rent Multiplier
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="ratios_grossrent"
                                        suffix=""
                                        min={0} max={100}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={true}
                                        onValueChange={
                                            handleRatiosInputChange
                                        }
                                        value={ratiosInput?.ratios_grossrent}
                                        // value={leftContentratiosInput?.purchasePrice ? FormatAmount(leftContentratiosInput?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Loan To Value Ratio</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="ratios_loanToValueRatio"
                                        suffix=" %"
                                        min={0} max={100}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={true}
                                        onValueChange={
                                            handleRatiosInputChange
                                        }
                                        value={ratiosInput?.ratios_loanToValueRatio}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Leverage</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="ratios_leverage"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0} max={100}
                                        disabled={true}
                                        onValueChange={
                                            handleRatiosInputChange
                                        }
                                        value={ratiosInput?.ratios_leverage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {renderFooter()} */}
                </div>
            </Panel>
    )
}

export default Ratios;