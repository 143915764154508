import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment } from "react";
import { FormatAmount } from "app/utils/utility.service";
import numeral from "numeral";
// import {CategoryScale} from 'chart.js';
// Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

// const data = {
//     labels: ["Jan", "Feb", "Mar", "Apr", "May"],
//     datasets: [
//       {
//         label: "Avg interest by month",
//         data: [0.7, 0.81, 0.71, 0.87, 0.9],
//         fill: false,
//         backgroundColor: "transparent",
//         borderColor: "#06a1e1",
//         tension: 0.1,
//         borderWidth: 4
//       }
//     ]
//   };


const BarChart = ({ data }) => {


    const plugins = [{
        afterLayout: chart => {
          let ctx = chart.chart.ctx;
          ctx.save();
          let yAxis = chart.scales["y-axis-0"];
          let yBottom = yAxis.getPixelForValue(0);
          let dataset = chart.data.datasets[2];
          dataset.backgroundColor = dataset.data.map(v => {
            let yTop = yAxis.getPixelForValue(v);
            let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
            gradient.addColorStop(0.4, '#FFFFFF');
            gradient.addColorStop(1, '#acd7fa');
            return gradient;
          });
          ctx.restore();
        }
      }]

    const options = {
        indexAxis: "y",
        responsive: true,
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                color: 'white',
                font: {
                    weight: "bold"
                }
              }
            },
            y: {
                ticks: {
                    color: 'white',
                    font: {
                        weight: "bold"
                    }
                  },
              grid: {
                display: false
              }
            }
          },
        responsive: true,
        plugins: {
            datalabels: {
                align: "end",
                anchor: "start",
                color: "#fff",
                formatter: function(value) {
                    return numeral(value).format("0,0");
                    // eq. return ['line1', 'line2', value]
                  },
                labels: {
                    title: {
                      font: {
                        weight: 'bold',
                        size: 16
                      },
                    },
                    value: {
                      color: '#fff',
                      font: {
                        weight: 'bold',
                        size: 16
                      },
                    },
                    
                },
            },
            
            legend: {
                display: false,
            },
        },
    };
    return (
    <Fragment>
        <div>
            <Bar id='canvas' data={data} options={options}  />
        </div>
        {/* <Issues /> */}
    </Fragment>
)};

export default BarChart;
