//Set constant value
export const PERCENT_VALUE = 0; //default percentage value
export const AMT_MIN = 50000; //default sliderMin
export const AMT_MAX = 500000; //default sliderMax
export const AMT_VALUE = 50000; //default value
export const PP_MIN = 50000; //Purchase price min
export const PP_MAX = 500000; //Purchase price max
export const PP_VALUE = 50000; //Purchase price
export const PROPERTY_TAX = 1.25;
export const INSURANCE = 1200;
export const HOME_WARRANTY = 350;
export const YEARS = 30; //years constant
export const secondLoanPercent = 0;
export const secondLoanInterestRate = 4.5;
export const HOA = 300;
export const FED_TAX = 24; //Federal Tax Rate
export const STATE_TAX = 9.30; //State Tax
export const Misc = 0;
export const map = { 94087: "4.00" };
export const ZIP_CODE_CONSTANT = 94087;
export const FIRST_LOAN_RATE = 4.00;
export const zipcodeFlag = false;
export const DP_MIN_PERCENTAGE = 3.5; //down payment min percentage
export const D_MAX_PERCENTAGE = 100; //down payment max percentage
export const SLIDER_MARGIN = 25; //slider margin
export const MAX_MORTGAGE_AMOUNT_FIRST = 750000;
export const CAP_ITEMIZATION_PROPERTY_TAX = 10000;
export const STANDARD_DEDUCTION = 24800;


// export const fLPMin = 0; //first loan min
// export const fLPMax = 100 - parseFloat(dPMinPercentage); //first loan max

// export const sLPMin = 0; //second loan min
// export const sLPMax = 10; //second loan max

// export const fRPMin = 1; //first(Rate) min
// export const fRPMax = 100; //first(Rate) max

// export const sRPMin = 1; //second(Rate) min
// export const sRPMax = 100; //second(Rate) max

// export const hOAMin = 0; //HOA min
// export const hOAMax = 100000; //HOA max

// export const pTMin = 1; //Property Tax min
// export const pTMax = 100; //Property Tax max

// export const fTMin = 0; //Federal Tax min
// export const fTMax = 100; //Federal Tax max

// export const sTMin = 0; //State Tax min
// export const sTMax = 100; //State Tax max

// export const insuranceMin = 0; //Insurance Min
// export const insuranceMax = 100000; //Insurance Max

// export const homewarrantyMin = 0; //Home Warranty min
// export const homewarrantyMax = 100000; //Home Warranty max
