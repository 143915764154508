const IncomeCalculationsHeader = () => {
    return (
        <div className="grid header text-center mb-3">
            <div className="col-12 md:col-3 lg:col-3">
                {/* <label className="mt-3">Principal Paid</label> */}
            </div>
            <div className="col-6 md:col-2 lg:col-4">
                <label style={{fontWeight:"bold"}}>Rental</label>
            </div>
            <div className="col-6 md:col-6 lg:col-4">
                <label style={{fontWeight:"bold"}}>Buy</label>
            </div>
        </div>
    );
}

export default IncomeCalculationsHeader;