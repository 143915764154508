import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const Dashboard= Loadable(lazy(() => import("./Dashboard")));
// const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
// const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const dashboardRoutes = [
    {
        path: "/dashboard",
        element: <Dashboard />,
    },
    
];

export default dashboardRoutes;
