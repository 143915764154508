import { ProgressSpinner } from "primereact/progressspinner";
import "./Loading.scss";
const Loading = () => (
    <div className="loading">
        {/* <div className="relative"> */}
            <ProgressSpinner />
        {/* </div> */}
    </div>
);
export default Loading;
