import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";
import Investment from "./HomePurchaseStep3/Investment/Investment";
import Tab2 from "./HomePurchaseStep3/RightContent/Tab2/Tab2";
import Tab3 from "./HomePurchaseStep3/RightContent/Tab3/Tab3";
import Tab4 from "./HomePurchaseStep3/RightContent/Tab4/Tab4";
import Tab1 from "./HomePurchaseStep3/RightContent/Tab1/Tab1";
import InvestmentAnalysis from "./HomePurchaseStep3/Investment/InvestmentAnalysis/InvestmentAnalysis";
import Assumptions from "./HomePurchaseStep3/Investment/Assumptions/Assumptions";
import Ratios from "./HomePurchaseStep3/Investment/Ratios/Ratios";

const HomePurchaseRentStep1 = Loadable(lazy(() => import("./HomePurchaseStep1")));
const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const homePurchaseRoutes = [
    {
        path: "/home-purchase/step1",
        element: <HomePurchaseRentStep1 />,
    },
    {
        path: "/home-purchase/step2",
        element: <HomePurchaseRentStep2 />,
    }, {
        path: "/home-purchase/step3",
        element: <HomePurchaseRentStep3 />,
        children: [
            {
                path: "tab1",
                 element: <Tab1 />,
                },
            {
            path: "tab2",
             element: <Tab2 />,
            },
            {
                path: "tab3",
                 element: <Tab3 />,
                },
                {
                    path: "tab4",
                     element: <Tab4 />,
                    }
        ]
    },
    {
        path: "/home-purchase/investment",
        element: <Investment />,
        children: [
            {
                path: "analysis",
                element: <InvestmentAnalysis />,
            },
            {
                path: "assumptions",
                element: <Assumptions />,
            },
            {
                path: "ratios",
                element: <Ratios />,
            },
        ]
    },
];

export default homePurchaseRoutes;
