import { Button } from "primereact/button";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

const IncomeCalculationsFooter = ({handleCalculationSave,setModal,showSubmit=true }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    return (
        <Fragment>
            <div className="grid mb-3">
                {showSubmit ? (
                    <Fragment>
                        <div className={`col-12 md:col-12 lg:col-3 ${isDesktopOrLaptop ? "ml-2" : ""}`}>     
                        </div>
                        <div class="grid-grossIncome">
                            <div className="md:mr-7 lg:mr-6">
                                <Button
                                    label="Cancel"
                                    className="p-button-raised p-button p-button-lg mr-3"
                                    onClick={() => setModal(false)}
                                />
                            
                                    <Button
                                        label="Save"
                                        className="p-button-raised p-button-warning p-button-lg"
                                        onClick={handleCalculationSave}
                                    />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className={`col-12 md:col-12 lg:col-3 ${isDesktopOrLaptop ? "ml-2" : "text-align-center"}`}>
                        
                        </div>
                        <div class="grid-grossIncome">
                            <div className="md:mr-2 lg:mr-6">
                                <Button
                                    label="Cancel"
                                    className="p-button-raised p-button p-button-lg mr-3"
                                    onClick={() => setModal(false)}
                                />
                            
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export default IncomeCalculationsFooter;