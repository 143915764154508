import { FormatAmount } from "app/utils/utility.service";
import { changeAnnualExpenses } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { getAnnualExpenseCalculation } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const AnnualExpense = ({
    annualExpenseModal,
    handleAnnualExpenseModal,
    handleExpensesSave,
}) => {
    const onHide = () => {
        handleAnnualExpenseModal(false);
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const annualExpenseValues = useSelector(getAnnualExpenseCalculation);
    const dispatch = useDispatch();
    const percent = ' %';
    const dollar = '$ ';
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"}`}>
                        <Button
                            label="Cancel"
                            className="p-button-raised p-button-lg"
                            onClick={() => onHide()}
                        />
                        <Button
                            label="Save"
                            className={`p-button-raised p-button-warning p-button-lg  ${isDesktopOrLaptop ? "" : "ml-4"}`}
                            onClick={handleExpensesSave}
                        />
                    </div>
                </div>
            </div>
        );
    };

    console.log()
    return (
        <Dialog
            header="Annual Expenses (Prorated Monthly)"
            visible={annualExpenseModal}
            onHide={() => onHide()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={renderFooter()}
            className="AnnualExpenseModal"
            position="top"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                {/* Property Taxes */}
                <div className="grid">
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                        <label className="mt-3">Property Taxes</label>
                    </div>
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                        <div className="field">
                            <div className="p-inputgroup">

                                <InputText
                                    className="vw-input-nfl"
                                    autoComplete="off"
                                    required={true}
                                    name="propertyTax"
                                    disabled={true}
                                    value={annualExpenseValues?.propertyTax+percent}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-1"></div> */}
                    <div className={`${isDesktopOrLaptop ? "col-4" : "col-11"}`}>
                        <div className="field">
                            <div className="p-inputgroup">

                                <InputText
                                    className="vw-input-nfl"
                                    autoComplete="off"
                                    required={true}
                                    name="dedPropertyTax"
                                    disabled={true}
                                    value={
                                        annualExpenseValues?.dedPropertyTax
                                            ? dollar+FormatAmount(
                                                  annualExpenseValues?.dedPropertyTax
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Insurance */}
                <div className="grid">
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                        <label className="mt-3">Insurance</label>
                    </div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                        <div className="field">
                            <div className="p-inputgroup">

                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    name="insurance"
                                    disabled={false}
                                    prefix="$ "
                                    value={annualExpenseValues?.insurance}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeAnnualExpenses({
                                                name: "insurance",
                                                value: e.target.value || e.target.value === 0 ? Number(e.target.value) : 0,
                                            })
                                        );
                                    }}
                                    // onBlur={(e) => {
                                    //     console.log(e.target.value)
                                    //     dispatch(
                                    //         changeAnnualExpenses({
                                    //             name: "insurance",
                                    //             value: e.target.value || e.target.value === 0 ? Number(e.target.value) : 0,
                                    //         })
                                    //     );
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Home Warranty */}
                <div className="grid">
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                        <label className="mt-3">Home Warranty</label>
                    </div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                        <div className="field">
                            <div className="p-inputgroup">
                               
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    name="homeWarranty"
                                    disabled={false}
                                    prefix="$ "
                                    value={annualExpenseValues?.homeWarranty}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeAnnualExpenses({
                                                name: "homeWarranty",
                                                value: e.target.value || e.target.value === 0 ? Number(e.target.value) : 0,
                                            })
                                        );
                                    }}
                                    // onBlur={(e) => {
                                    //     dispatch(
                                    //         changeAnnualExpenses({
                                    //             name: "homeWarranty",
                                    //             value: e.target.value || e.target.value === 0 ? Number(e.target.value) : 0,
                                    //         })
                                    //     );
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Prorated Monthly Expense */}
                <div className="grid">
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                        <label className="mt-3">Prorated Monthly Expense</label>
                    </div>
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                        <div className="field">
                            <div className="p-inputgroup">

                                <InputText
                                    className="vw-input-nfl"
                                    autoComplete="off"
                                    required={true}
                                    name="proratedMonthlyExpense"
                                    disabled={true}
                                    value={
                                        annualExpenseValues?.proratedMonthlyExpense
                                            ? dollar+FormatAmount(
                                                  annualExpenseValues?.proratedMonthlyExpense
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AnnualExpense;
