const { createSlice } = require("@reduxjs/toolkit");

 const initialState = {
     loggedInUser: null,
     userSettings: null
 };

 const SessionSlice = createSlice({
     name: "sessionInfo",
     initialState,
     reducers: {
         storeLoggedInData: (state, action) => {
             state.loggedInUser = action.payload;
         },
         storeUserSettings: (state, action) => {
             state.userSettings = action.payload;
         }
     }
 })

 const { actions } = SessionSlice;

 export const {
    storeLoggedInData,
    storeUserSettings
 } = actions;

 export const getLoggedInInfo = (state) => state.sessionInfo.loggedInUser;
 export const getUserSettings = (state) => state.sessionInfo.userSettings;

 export default SessionSlice.reducer;
