import { useMediaQuery } from "react-responsive";
import IncomeCalculationsFooter from "./common/IncomeCalculationsFooter";
import IncomeCalculationsHeader from "./common/IncomeCalculationsHeader";

const { Dialog } = require("primereact/dialog");
const { InputNumber } = require("primereact/inputnumber");

const HousingModal = ({
    housingModal,
    incomeCalculations,
    handleIncomeCalculationInputChange,
    setHousingModal,
    handleCalculationSave
}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });

    const afterTax = () => {
        return (
            <div className="grid">
                <div className="col-6 md:col-4 lg:col-3">
                    <label className="mt-3">
                        After Tax
                    </label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="totalNett"
                                    disabled={true}
                                    prefix="$ "
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    value={incomeCalculations?.totalNett}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="totalNettBuy"
                                    prefix="$ "
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalNettBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const addbackStandardDeduction = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Add-Back Standard Deduction</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="addbackStandardDeduction"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={
                                        incomeCalculations?.addbackStandardDeduction
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="addbackStandardDeduction"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    // value={incomeCalculations?.addbackStandardDeduction}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const incomeBeforeHousing = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Income Before Housing</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="totalGross"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalGross}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="totalGross"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalNettBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const rent = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Rent</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="rent"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.rent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="rent"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.rentBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const hoaEquivalent = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">HOA Equivalent</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="hoaEquivalent"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.hoaEquivalent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="hoaEquivalent"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.hoaEquivalentBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const principalPaid = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Principal Paid</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="principalPaid"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="principalPaid"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.principalPaidBuy}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        );
    };

    const nonDeductibleInterest = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Non Deductible Interest</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="netRealizationBuy"
                                    disabled={true}
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="netRealizationBuy"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={
                                        incomeCalculations?.nonDeductibleInterestBuy
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const nonDeductiblePropertyTax = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">
                        Non Deductible Property Taxes
                    </label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    prefix="$ "
                                    required={true}
                                    name="netRealizationBuy"
                                    disabled={true}
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    // value={incomeCalculations?.principalPaid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="nonDeductiblePropertyTax"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={
                                        incomeCalculations?.nonDeductiblePropertyTaxBuy
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const postHousingTakeHome = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Post Housing Take Home</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="postHousingTakeHomeRental"
                                    disabled={true}
                                    // onValueChange={handleIncomeCalculationInputChange}
                                    value={
                                        incomeCalculations?.postHousingTakeHomeRental
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="postHousingTakeHomeBuy"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={
                                        incomeCalculations?.postHousingTakeHomeBuy ? incomeCalculations?.postHousingTakeHomeBuy?.toFixed(0) : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Housing"
            position="top"
            visible={housingModal}
            onHide={() => setHousingModal(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                <IncomeCalculationsHeader />
                {afterTax()}
                {addbackStandardDeduction()}
                {incomeBeforeHousing()}
                {rent()}
                {hoaEquivalent()}
                {principalPaid()}
                {nonDeductibleInterest()}
                {nonDeductiblePropertyTax()}
                {postHousingTakeHome()}
                <IncomeCalculationsFooter
                    handleCalculationSave={handleCalculationSave}
                    setModal={setHousingModal}
                    showSubmit={false}
                />
            </div>
        </Dialog>
    );
};

export default HousingModal;