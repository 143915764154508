import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import homePurchaseStep3 from "../app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import sessionInfo from "../app/views/sessions/SessionSlice";

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		homePurchaseStep3,
		sessionInfo
	},
});
