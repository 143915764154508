import { InputNumber } from "primereact/inputnumber";
import { Fragment } from "react";

const ProfitLoss = ({ purchasePriceInfo, handleInvestmentInputChange }) => {
    const renderIncomeInput = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Rental Income</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="income_rental"
                                    disabled={true}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.income_rental}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Total</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="income_total"
                                    disabled={true}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.income_total}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderExpensesInput = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Interest</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_interest"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_interest}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Property Taxes</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_propertyTax"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_propertyTax}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">HOA</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_hoa"
                                    disabled={false}
                                    onValueChange={handleInvestmentInputChange}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_hoa}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Insurance</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_insurance"
                                    disabled={false}
                                    onValueChange={handleInvestmentInputChange}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_insurance}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Maintenance</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_maintenance"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_maintenance}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Property Management</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_propertyManagement"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_propertyManagement}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Utilities</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_utilities"
                                    disabled={false}
                                    onValueChange={handleInvestmentInputChange}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_utilities}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Operating Expenses</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_operatingexpenses"
                                    disabled={true}
                                    onValueChange={handleInvestmentInputChange}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_operatingexpenses}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">NOI</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_noi"
                                    disabled={true}
                                    onValueChange={handleInvestmentInputChange}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_noi}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Total</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_total"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_total}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Profit / Loss</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_profitloss"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_profitloss}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Depreciation</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="land"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.expenses_depreciation}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Taxable Income</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="expenses_taxable_income"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={Math.round(purchasePriceInfo?.expenses_taxable_income)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderInputs = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-6">
                        <h4>Income</h4>
                        {renderIncomeInput()}
                    </div>

                    <div className="col-6">
                        <h4>Expenses</h4>{renderExpensesInput()}</div>
                </div>
            </Fragment>
        );
    };

    return renderInputs();
};

export default ProfitLoss;
