import { Fragment } from "react";
import IncomeCalculationsFooter from "./common/IncomeCalculationsFooter";
import IncomeCalculationsHeader from "./common/IncomeCalculationsHeader";
import { useMediaQuery } from "react-responsive";

const { Dialog } = require("primereact/dialog");
const { InputNumber } = require("primereact/inputnumber");

const GrossIncomeModal = ({
    grossIncomeModal,
    incomeCalculations,
    handleIncomeCalculationInputChange,
    setGrossIncomeModal,
    handleCalculationSave
}) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const renderSelfIncome = () => {
        return (
            <div className="grid">
             <div className="col-6 md:col-3 lg:col-3">
                    <label className="mt-3">Your Income</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="selfIncome"
                                    min={0}
                                    disabled={false}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.selfIncome}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="selfIncome"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.selfIncome}
                                />
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        );
    };

    const renderSpouseIncome = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Spouse Income</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="spouseIncome"
                                    disabled={false}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.spouseIncome}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="spouseIncome"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.spouseIncome}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const othersIncome = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Others</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="others"
                                    prefix="$ "
                                    disabled={false}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.others}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="others"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.others}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const grossIncome = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Gross Income</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="gross"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.gross}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="gross"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.gross}
                                />
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Gross Income"
            position="top"
            visible={grossIncomeModal}
            onHide={() => setGrossIncomeModal(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                <IncomeCalculationsHeader />
                {renderSelfIncome()}
                {renderSpouseIncome()}
                {othersIncome()}
                {grossIncome()}
                <IncomeCalculationsFooter
                    handleCalculationSave={handleCalculationSave}
                    setModal={setGrossIncomeModal}
                />
            </div>
        </Dialog>
    );
};

export default GrossIncomeModal;