import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, fetchSignInMethodsForEmail, getAuth, getRedirectResult, onAuthStateChanged, signInWithPopup, signInWithRedirect, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export function onAuthStateChange() {
  return onAuthStateChanged(user => {
    if (user) {
      console.log("The user is logged in");
    } else {
      console.log("The user is not logged in");
    }
  });
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.addScope("email");
  return signInWithPopup(auth, provider)
    .then((result) => {
      if(result.user?.providerData && result.user?.providerData.length > 0) {
        return {providerData: result.user?.providerData[0]};
      }
      return null;
      
      // navigate("/home-purchase/step1");
    }).catch((error) => {

      console.log({ error });

    });
};
function getProvider(providerId) {
  switch (providerId) {
    case GoogleAuthProvider.PROVIDER_ID:
      return new GoogleAuthProvider();
    case FacebookAuthProvider.PROVIDER_ID:
      return new FacebookAuthProvider();
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
}
export const signInWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  provider.addScope("email");
  const supportedPopupSignInMethods = [
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID
  ];
  return signInWithPopup(auth, provider)
    .then(function (result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      //   var token = result.credential.accessToken;
      // The signed-in user info.
      console.log("res",result);
      if(result.user?.providerData && result.user?.providerData.length > 0) {
        return {providerData: result.user?.providerData[0]};
      }
      // ...
    })
    .catch(function (error) {
      console.log(error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      if (error.email && error.credential && error.code === 'auth/account-exists-with-different-credential') {
       fetchSignInMethodsForEmail(error.email)
          .then(function (providers) {
            const firstPopupProviderMethod = providers.find(p => supportedPopupSignInMethods.includes(p));
            if (!firstPopupProviderMethod) {
              throw new Error(`Your account is linked to a provider that isn't supported.`);
            }

            const linkedProvider = getProvider(firstPopupProviderMethod);
            linkedProvider.setCustomParameters({ login_hint: error.email });

            signInWithPopup(linkedProvider)
              .then(function (result) {
                result.user.linkWithCredential(error.credential);
              });
          });
      }

    });
};
export const signInWithMicrosoft = async () => {
  const providerMS = new OAuthProvider('microsoft.com');
  providerMS.setCustomParameters("prompt", "consent");
  return signInWithPopup(auth, providerMS)
    .then(function (result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      //const credential = OAuthProvider.credentialFromResult(result);
      // const accessToken = credential.accessToken;
      // const idToken = credential.idToken; 
      console.log('microsoft',result);
      if(result.user?.providerData && result.user?.providerData.length > 0) {
        return {providerData: result.user?.providerData[0]};
      }
      // ...
    })
    .catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.log("error ".error);
      // ...
    });

};
export const logout = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
    console.log(' Logout successful');
  }).catch((error) => {
    // An error happened.
    console.log({ error });
  });
}; 
