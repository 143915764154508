import { Card } from "primereact/card";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const LeftContent = ({ handleLeftCalculations, mortgageMonthlyExpenses }) => {

    const [searchParams] = useSearchParams();
    const clientId = searchParams.get("clientid");
    const navigate = useNavigate();
    const types = ["Home Ownership", "Investment"];
    const [active, setActive] = useState(null);
    const { pathname } = useLocation();
    const gotoTab = (type) => {
        if(!clientId || clientId === "null") {
            if(type === 'Home Ownership') {
                navigate(`/home-purchase/step3/tab1`);
            } else {
                navigate(`/home-purchase/investment/analysis`);//to be added
            }
        } else {
            if(type === 'Home Ownership') {
                navigate(`/home-purchase/step3/tab1/?clientid=${clientId}`);
            } else {
                navigate(`/home-purchase/investment/analysis?clientid=${clientId}`);//to be added
            }
        }
	}

    useEffect(() => {
        if(pathname.search("/home-purchase/step3")) {
            setActive(types[1])
        } else {
            setActive(types[0])
        }
    }, [pathname])

    return (
        <Card>
            <div className="tabs">
                <div className="tabs-nav">
                {types.map((type) => (
                    <div
                    className={`${active === type ? "nav-item active" : "nav-item"}`}
                    id={type}
                    key={type}
                    onClick={() => gotoTab(type)}
                    >
                    {type}
                    </div>
                ))}
                </div>
            </div>
        </Card>
    );
};

export default LeftContent;
