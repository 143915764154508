export const PURCHASE_PRICE_INITIAL_STATE = {
    purchasePrice: null,
    mortgage: null,
    monthlyRent: null,
    land: null,
    improvement: null,
    interestRate: null,
    monthlyMortgage: null,
    interestAmount: null,
    propertyTaxes: null,
    maintenance: null
}

export const ASSUMPTION_CONSTANTS = {
    percentageDown: 25,
    interestRate: 7.00,
    propertyTaxRate: 2.75,
    amortization: 30,
    propertyManagementFees: 7,
    vacancyFactor: 5,
    propertyInfo: null,
    builtUp: 2100,
    constructionCost: 200,
    maintenance: 0
}