import { useMediaQuery } from "react-responsive";
import IncomeCalculationsFooter from "./common/IncomeCalculationsFooter";
import IncomeCalculationsHeader from "./common/IncomeCalculationsHeader";

const { Dialog } = require("primereact/dialog");
const { InputNumber } = require("primereact/inputnumber");

const TotalTaxModal = ({
    totalTaxModal,
    incomeCalculations,
    handleIncomeCalculationInputChange,
    setTotalTaxModal,
    handleCalculationSave
}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const federal = () => {
        return (
            <div className="grid">
                <div className="col-6 md:col-3 lg:col-3">
                    <label className="mt-3">Federal</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="federal"
                                    suffix=" %"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    min={0} max={100}
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.federal}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="federal"
                                    suffix=" %"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    disabled={true}
                                    min={0} max={100}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.federal}
                                />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        );
    };

    const state = () => {
        return (
            <div className="grid">
                <div className="col-6 md:col-3 lg:col-3">
                    <label className="mt-3">State</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="state"
                                    suffix=" %"
                                    min={0} max={100}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.state}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    suffix=" %"
                                    name="state"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.state}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const totalTax = () => {
        return (
            <div className="grid">
                <div className="col-6 md:col-3 lg:col-3">
                    <label className="mt-3">Total Tax</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>    
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="totalTax"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalTax}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="totalTax"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalTaxBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Total Tax"
            position="top"
            visible={totalTaxModal}
            onHide={() => setTotalTaxModal(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                <IncomeCalculationsHeader />
                {federal()}
                {state()}
                {totalTax()}
                <IncomeCalculationsFooter
                    handleCalculationSave={handleCalculationSave}
                    setModal={setTotalTaxModal}
                    showSubmit={false}
                />
            </div>
        </Dialog>
    );
};

export default TotalTaxModal;