import { getSessionAgentInformation } from "app/utils/utility.service";
import { getSessionBrokerInformation } from "app/utils/utility.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { clearHomePurchaseValues } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Menu } from 'primereact/menu';
import { Fragment, useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Partials.scss";
const ClientHeader = ({agentValues, brokerValues}) => {
    const menu = useRef(null);
    const [disableLogoLink, setDisableLogoLink] = useState(false);
    const sessionValues = getSessionUserInformation();
    const navigate  = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    
    let items = [];
    
    const myProfileItems = [
        {
            label: "My Profile",
            command: () => {
                window.location.hash = `/my-profile`;
            },
        }]

    const commonMenuItems = [
        {
            label: "Logout",
            command: () => {
                dispatch(clearHomePurchaseValues());
                sessionStorage.clear();
                navigate("/");
            },
        },
    ];

    items = [...commonMenuItems];

    if(disableLogoLink === false) {
        items = [...myProfileItems,...commonMenuItems];
    }

    useEffect(() => {
        if(pathname.search("/home-purchase/step1") === 0 || pathname.search("/home-purchase/step2") === 0) {
            setDisableLogoLink(true);
        } else {
            setDisableLogoLink(false);
        }
    },[pathname])

    
    let superAdminMenuItems = [
        {
            label: "Dashboard",
            command: () => {
                window.location.hash = "/dashboard";
            }
        },
        {
            label: "Broker/Team Leader List",
            command: () => {
                window.location.hash = "/brokers";
            }
        },
        {
            label: "Agent List",
            command: () => {
                window.location.hash = "/agents";
            }
        },
        {
            label: "Client List",
            command: () => {
                window.location.hash = "/clients";
            },
        },
        {
            label: "Unassigned Clients",
            command: () => {
                window.location.hash = "/clients/new";
            }
        },
    ];

    let brokerMenuItems = [
        {
            label: "Dashboard",
            command: () => {
                window.location.hash = "/dashboard";
            }
        },
        {
            label: "Agent List",
            command: () => {
                window.location.hash = `/agents/?userId=${sessionValues.id}`;
            },
        },
        {
            label: "Client List",
            command: () => {
                window.location.hash = `/clients/?userId=${sessionValues.id}`;
            },
        },
    ];
    let agentMenuItems = [
        {
            label: "Dashboard",
            command: () => {
                window.location.hash = "/dashboard";
            }
        },
        {
            label: "Client List",
            command: () => {
                window.location.hash = `/clients/?userId=${sessionValues.id}`;
            },
        },
    ];

    
    if(sessionValues?.roleId === 1) {
        items = [...superAdminMenuItems, ...items];
    }
    if(sessionValues?.roleId === 2) {
        items = [...brokerMenuItems, ...items];
    }
    if(sessionValues?.roleId === 3) {
        items = [...agentMenuItems, ...items];
    }

    return (
        <div className="container-fluid">
            <div className="Header">
                <div className="left">
                    {disableLogoLink ? (
                        <img src="/myhouserlogo.png" alt="Company" />
                    ) : (
                        <Link to="/">
                            <img src="/myhouserlogo.png" alt="Company" />
                        </Link>
                    )}
                </div>
                <div className="main">
                    <Fragment>
                        {/* {sessionValues &&
                            sessionValues.roleId === 2 &&
                            sessionValues?.companyImagePath && (
                                <Fragment>
                                    <img
                                        className="ml-4 mr-4"
                                        src={`${process.env.REACT_APP_API_BASE_URL}/fetchImages/${sessionValues?.companyImagePath}`}
                                        alt="Profile"
                                        style={{
                                            width: "auto",
                                            height: "70px",
                                        }}
                                    />
                                </Fragment>
                            )} */}
                        {brokerValues &&
                            brokerValues.id &&
                            brokerValues.companyImagePath && (
                                <Fragment>
                                    <div style={{ marginRight: "15rem" }}>
                                        <img
                                            className="ml-4 mr-4"
                                            src={`${process.env.REACT_APP_API_BASE_URL}/fetchImages/${brokerValues?.companyImagePath}`}
                                            alt="Company"
                                            style={{
                                                width: "auto",
                                                height: "70px",
                                            }}
                                        />
                                        <div className="text-center">
                                            <label
                                                style={{ fontWeight: "500" }}
                                            >{`Broker: ${brokerValues.firstName} ${brokerValues.lastName}`}</label>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        {agentValues &&
                            agentValues.id &&
                            agentValues.profileImagePath && (
                                <Fragment>
                                    <div>
                                        <img
                                            className="ml-4 mr-4"
                                            src={`${process.env.REACT_APP_API_BASE_URL}/fetchImages/${agentValues?.profileImagePath}`}
                                            alt="Profile"
                                            style={{
                                                width: "auto",
                                                height: "70px",
                                            }}
                                        />
                                        <div className="text-center">
                                            <label
                                                style={{ fontWeight: "500" }}
                                            >{`Agent: ${agentValues.firstName} ${agentValues.lastName}`}</label>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                    </Fragment>
                </div>
                {!!sessionValues?.firstName && (
                    <Fragment>
                        <div className="right">
                            <h4>Hi {sessionValues?.firstName}</h4>
                            <Menu
                                model={items}
                                popup
                                ref={menu}
                                id="popup_menu"
                            />
                            <i
                                className="pi pi-angle-down ml-2"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={(event) => menu.current.toggle(event)}
                                aria-controls="popup_menu"
                                aria-haspopup
                            ></i>

                            {/* <div className="mr-5">
                        <Link to="/session/signin/step1">Login</Link>
                    </div>
                    <div>
                        <Link to="/session/signup">Register</Link>
                    </div> */}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default ClientHeader;
