import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useMediaQuery } from "react-responsive";

const DirectIncomeModal = ({
    directIncomeModal,
    handleDirectIncomeModal,
    directIncome,
    handleDirectIncomeInputChange,
    handleDirectIncomeSave
}) => {
    const onHide = () => {
        handleDirectIncomeModal(false);
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className="col-7">
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-raised p-button p-button-lg"
                                onClick={() => onHide()}
                            />
                            {/* <Button
                                label="Save"
                                className="p-button-raised p-button-warning p-button-lg"
                                onClick={handleDirectIncomeSave}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderGrossPreTax = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Gross Pretax Salary</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="selfGrossPreTaxSalary"
                                    disabled={true}
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.selfGrossPreTaxSalary}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="spouseGrossPreTaxSalary"
                                    disabled={true}
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.spouseGrossPreTaxSalary}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOvertime = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-4 lg:col-3">
                    <label className="mt-3">Overtime</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-4 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="selfOverTime"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.selfOverTime}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="spouseOverTime"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.spouseOverTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderBonus = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Others</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="selfBonus"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.selfBonus}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="spouseBonus"
                                    prefix="$ "
                                    disabled={true}
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.spouseBonus}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommissions = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Commissions</label>
                </div>
                <div className="col-12 md:col-12 lg:col-4">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfCommissions"
                                prefix="$ "
                                disabled={true}
                                onValueChange={handleDirectIncomeInputChange}
                                value={directIncome?.selfCommissions}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-4">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="spouseCommissions"
                                disabled={true}
                                onValueChange={handleDirectIncomeInputChange}
                                value={directIncome?.spouseCommissions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDirectIncome = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Direct Income</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="selfDirectIncome"
                                    disabled={true}
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.selfDirectIncome}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="spouseDirectIncome"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={handleDirectIncomeInputChange}
                                    value={directIncome?.spouseDirectIncome}
                                />
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>
        );
    };

    return (
        <Dialog
            header="Direct Income"
            visible={directIncomeModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={renderFooter()}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3"></div>
                <div className="col-6 md:col-2 lg:col-4">
                    <h4>Self</h4>
                </div>
                <div className="col-6 md:col-2 lg:col-4">
                    <h4>Spouse</h4>
                </div>
            </div>
            <div className={`${isDesktopOrLaptop ? "align-text" : ""}`}>
                {renderGrossPreTax()}
                {/* {renderOvertime()} */}
                {renderBonus()}
                {/* {renderCommissions()} */}
                {renderDirectIncome()}
            </div>
        </Dialog>
    );
};

export default DirectIncomeModal;