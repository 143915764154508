import { getSessionUserInformation } from "app/utils/utility.service";
import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../components";
import homePurchaseRoutes from "../views/home-purchase/HomePurchaseRoutes";
import sessionRoutes from "../views/sessions/SessionRoutes";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import brokerRoutes from "app/views/brokers/BrokerRoutes";
import agentRoutes from "app/views/agents/AgentRoutes";
import clientRoutes from "app/views/clients/ClientRoutes";
import reportsRoutes from "app/views/reports/ReportsRoutes";

export const AllPages = () => {
    const sessionValues = getSessionUserInformation();
    const all_routes = [
        {
            element: <DefaultLayout />,
            children: [
                ...sessionRoutes,
                ...homePurchaseRoutes,
                ...dashboardRoutes,
                ...brokerRoutes,
                ...agentRoutes,
                ...clientRoutes,
                ...reportsRoutes
            ],
        },
    ];
    if(!!sessionValues?.firstName) {
        if(sessionValues.roleId !== 4) {
            all_routes.push({
                path: "/",
                element: <Navigate to="/dashboard" />,
            });
        } else {
            all_routes.push({
                path: "/",
                element: <Navigate to="/home-purchase/step3/tab1" />,
            });
        }
    } else {
        all_routes.push({
            path: "/",
            element: <Navigate to="/session/signin/step1" />,
        });
    }
    return all_routes;
};
