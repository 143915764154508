import React, { Fragment } from "react";
import { useRoutes } from "react-router-dom";
import { AllPages } from "./app/routes/routes";

const App = () => {
    const all_pages = useRoutes(AllPages());
    return <>{all_pages}</>;
};

export default App;
