import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import { FormatAmount } from 'app/utils/utility.service';

const DirectIncomeGraphModal = ({
    directIncomeGraphModal,
    handleDirectIncomeGraphModal,
    directIncome,
}) => {
    const onHide = () => {
        handleDirectIncomeGraphModal(false);
    };

    const [basicData, setBasicData] = useState({
        labels: ["Gross Pretax Salary",  "Others",],
        datasets: [
            {
                label: `Self - ${
                    directIncome?.selfDirectIncome
                        ? FormatAmount(directIncome?.selfDirectIncome)
                        : 0
                }`,
                backgroundColor: "#42A5F5",
                data: [
                    directIncome?.selfGrossPreTaxSalary,
                    directIncome?.selfBonus,
                ],
            },
            {
                label: `Spouse - ${
                    directIncome?.spouseDirectIncome
                        ? FormatAmount(directIncome?.spouseDirectIncome)
                        : 0
                }`,
                backgroundColor: "#FFA726",
                data: [
                    directIncome?.spouseGrossPreTaxSalary,
                    directIncome?.spouseBonus,
                ],
            },
        ],
    });

    useEffect(()=>{
        setBasicData((prev)=>({
            ...prev,
            datasets: [
                {
                    ...prev.datasets[0],
                    label: `Self - ${
                        directIncome?.selfDirectIncome
                            ? FormatAmount(directIncome?.selfDirectIncome)
                            : 0
                    }`,
                    data: [
                        directIncome?.selfGrossPreTaxSalary,
                        directIncome?.selfBonus,
                    ],
                },
                {
                    ...prev.datasets[1],
                    label: `Spouse - ${
                        directIncome?.spouseDirectIncome
                            ? FormatAmount(directIncome?.spouseDirectIncome)
                            : 0
                    }`,
                    data: [
                        directIncome?.spouseGrossPreTaxSalary,
                        directIncome?.spouseBonus,
                    ],
                }
            ]
        }))
    },[directIncomeGraphModal])

    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
            y: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
        },
    };

    


    return (
        <Dialog
            header="Direct Income Graph"
            visible={directIncomeGraphModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw" }}
            // footer={renderFooter()}
            className="MonthlyExpenseModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="align-text">
                <Chart type="bar" data={basicData} options={basicOptions} />
            </div>
        </Dialog>
    );
};

export default DirectIncomeGraphModal