import { InputNumber } from "primereact/inputnumber";
import { Fragment } from "react";

const Cashflow = ({ purchasePriceInfo, handleInvestmentInputChange }) => {
    const renderInflowInput = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Inflow</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="income_rental"
                                    disabled={true}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.inflow}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Cap Rate</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    suffix=" %"
                                    name="income_total"
                                    disabled={true}
                                    maxFractionDigits={2}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.inflow_caprate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderOutflowInput = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Outflow</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="outflow"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.outflow}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Positive</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="outflow_positive"
                                    disabled={true}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.outflow_positive}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Cash on Cash %</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    suffix=" %"
                                    name="outflow_cashpercentage"
                                    disabled={true}
                                    onValueChange={handleInvestmentInputChange}
                                    maxFractionDigits={2}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.outflow_cashpercentage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Add Contribution to Principle</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="outflow_contribution_to_principle"
                                    disabled={true}
                                    onValueChange={handleInvestmentInputChange}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.outflow_contribution_to_principle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="">
                            <label className="mb-2">Cap Rate</label>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true}
                                    suffix=" %"
                                    name="outflow_caprate"
                                    disabled={true}
                                    maxFractionDigits={2}
                                    // min={0}
                                    // onValueChange={(e) => {
                                    //     dispatch(
                                    //         changeLeftCalculations({
                                    //             name: "purchasePrice",
                                    //             value: e.value,
                                    //         })
                                    //     );
                                    //     handleLeftCalculations(
                                    //         "purchasePrice",
                                    //         e.value
                                    //     );
                                    // }}
                                    value={purchasePriceInfo?.outflow_caprate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderInputs = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-6">
                        <h4>Inflow</h4>
                        {renderInflowInput()}
                    </div>

                    <div className="col-6">
                        <h4>Outflow</h4>{renderOutflowInput()}</div>
                </div>
            </Fragment>
        );
    };

    return renderInputs();
};

export default Cashflow;
