import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";
import { Fragment, useEffect, useState } from "react";
import { insertLogs } from "../../homepurchase.service";
import AppreciationModal from "./components/AppreciationModal";
import GrossIncomeModal from "./components/GrossIncomeModal";
import HousingModal from "./components/HousingModal";
import TaxDeductionModal from "./components/TaxDeductionsModal";
import TotalTaxModal from "./components/TotalTaxModal";
import { useDispatch, useSelector } from "react-redux";
import { changeLeftCalculations, getLeftCalculations } from "../../HomePurchaseStep3Slice";
import { useMediaQuery } from "react-responsive";
import PurchasePrice from "./components/PurchasePrice";

const Tab2 = (props) => {
    const {
        incomeCalculations,
        handleIncomeCalculationInputChange,
        handleIncomeCalculationSave,
        calcIncomeCalculationstotal,
        handleLeftCalculations
    } = props;

    useEffect(() => {
        const payload = {
            description: "View Income Calculations",
        };
        calcIncomeCalculationstotal(incomeCalculations);
        insertLogs(payload);
    }, []);

    const [grossIncomeModal, setGrossIncomeModal] = useState(false);
    const [taxDeductionModal, setTaxDeductionModal] = useState(false);
    const [totalTaxModal, setTotalTaxModal] = useState(false);
    const [housingModal, setHousingModal] = useState(false);
    const [appreciationModal, setAppreciationModal] = useState(false);
    const leftContentValues = useSelector(getLeftCalculations);
    const dispatch = useDispatch();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px) and (max-width: 1024px)'
      });
    const handleCalculationSave = async () => {
        const record = await handleIncomeCalculationSave();
        if (record) {
            setGrossIncomeModal(false);
            setTaxDeductionModal(false);
            setTotalTaxModal(false);
            setHousingModal(false);
            setAppreciationModal(false);
        }
    };

    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-6">
                        <div className="mr-5">
                            {/* <Button
								label="Cancel"
								className="p-button-raised p-button p-button-lg"
								// onClick={() => onHide()}
							/> */}
                            <Button
                                label="Save"
                                className="p-button-raised p-button-warning p-button-lg"
                                onClick={handleIncomeCalculationSave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const grossIncome = () => {
        return (
            <div className="grid">
                {isDesktopOrLaptop ? (
                    <Fragment>
                        {grossIncomeLabel()}
                        {grossIncomeFields()}
                    </Fragment>
                ):
                    <div className={`${isTablet ? "w-90" : ""}`}>
                        {grossIncomeLabel()}
                        <div className="grossincome">
                            {grossIncomeFields()}
                        </div>
                    </div>
                }
            </div>
        );
    };
    const grossIncomeLabel = () => {
        return(
            <div className="col-12 md:col-12 lg:col-3">
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Gross Income</label>
            </div>
        );
    };
    const grossIncomeFields = () => {
        return(
            <Fragment>
                <div className="lg:ml-0 col-6 md:col-6 lg:col-4">
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="gross"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.gross}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-6 md:col-6 lg:col-4  ${isDesktopOrLaptop ? "" : "ml-3"} ${isTablet ? "ml-7" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="gross"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.gross}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1">
                    <a
                        href="javascript:;"
                        className="relative pencil-icon-income"
                        onClick={() => setGrossIncomeModal(true)}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
            </Fragment>
        );
    };
    const totalDeductions = () => {
        return (
            <div className="grid">
                {isDesktopOrLaptop ? (
                    <Fragment>
                        {totalDeductionsLabel()}
                        {totalDeductionsFields()}
                    </Fragment>
                ):
                    <div className={`${isTablet ? "w-90" : ""}`}>
                        {totalDeductionsLabel()}
                        <div className="grossincome">
                            {totalDeductionsFields()}
                        </div>
                    </div>
                }
                
            </div>
        );
    };
    const totalDeductionsLabel = () => {
        return (
            <div className="col-12 md:col-12 lg:col-3">
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Total Deductions</label>
            </div>
        )};
    const totalDeductionsFields = () => {
        return (
            <Fragment>
                <div className="lg:ml-0 col-6 md:col-6 lg:col-4">
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="totalDeductionsRent"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.totalDeductionsRent}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-6 md:col-6 lg:col-4 ${isDesktopOrLaptop ? "" : "ml-3"}  ${isTablet ? "ml-7" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="totalDeductionsBuy"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.totalDeductionsBuy}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1">
                    <a
                        href="javascript:;"
                        className="relative pencil-icon-income"
                        onClick={() => setTaxDeductionModal(true)}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
            </Fragment>
        )};

    const totalTax = () => {
        return (
            <div className="grid">
                {isDesktopOrLaptop ? (
                    <Fragment>
                        {totalTaxLabel()}
                        {totalTaxFields()}
                    </Fragment>
                ):
                    <div className={`${isTablet ? "w-90" : ""}`}>
                        {totalTaxLabel()}
                        <div className="grossincome">
                            {totalTaxFields()}
                        </div>
                    </div>
                }
                
            </div>
        );
    };
    const totalTaxLabel = () => {
        return (
            <div className="col-12 md:col-2 lg:col-3">
                    <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Total Tax</label>
            </div>
        )};
    const totalTaxFields = () => {
        return (
            <Fragment>
                <div className="lg:ml-0 col-6 md:col-6 lg:col-4">
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="totalTax"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.totalTax}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-6 md:col-6 lg:col-4 ${isDesktopOrLaptop ? "" : "ml-3"}  ${isTablet ? "ml-7" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="totalTax"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.totalTaxBuy}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1">
                    <a
                        href="javascript:;"
                        className="relative pencil-icon-income"
                        onClick={() => setTotalTaxModal(true)}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
            </Fragment>
        )};

    const postHousingTakeHome = () => {
        return (
            <div className="grid">
               {isDesktopOrLaptop ? (
                    <Fragment>
                        {postHousingTakeHomeLabel()}
                        {postHousingTakeHomeFields()}
                    </Fragment>
                ):
                    <div className={`${isTablet ? "w-90" : ""}`}>
                        {postHousingTakeHomeLabel()}
                        <div className="grossincome">
                            {postHousingTakeHomeFields()}
                        </div>
                    </div>
                }
                
            </div>
        );
    };
    const postHousingTakeHomeLabel = () => {
        return (
            <div className="col-12 md:col-12 lg:col-3">
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Post Housing Take Home</label>
            </div>
        )};
    const postHousingTakeHomeFields = () => {
        return (
            <Fragment>
                <div className="lg:ml-0 col-6 md:col-6 lg:col-4">
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" 
                                required={true} prefix="$ "
                                name="postHousingTakeHomeRental"
                                disabled={true}
                                // onValueChange={handleIncomeCalculationInputChange}
                                value={
                                    incomeCalculations?.postHousingTakeHomeRental
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-6 md:col-6 lg:col-4 ${isDesktopOrLaptop ? "" : "ml-3"}  ${isTablet ? "ml-7" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true} prefix="$ "
                                name="postHousingTakeHomeBuy"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={
                                    incomeCalculations?.postHousingTakeHomeBuy
                                        ? incomeCalculations?.postHousingTakeHomeBuy?.toFixed(
                                              0
                                          )
                                        : 0
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1">
                    <a
                        href="javascript:;"
                        className="relative pencil-icon-income"
                        onClick={() => setHousingModal(true)}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
            </Fragment>
        )};

    const totalCalculation = () => {
        return (
            <div className="grid">
                {isDesktopOrLaptop ? (
                    <Fragment>
                        {totalCalculationLabel()}
                        {totalCalculationFields()}
                    </Fragment>
                ):
                    <div className={`${isTablet ? "w-90" : ""}`}>
                        {totalCalculationLabel()}
                        <div className="grossincome">
                            {totalCalculationFields()}
                        </div>
                    </div>
                }
                
            </div>
        );
    };

    const totalCalculationLabel = () => {
        return (
            <div className="col-12 md:col-12 lg:col-3">
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`} style={{ fontWeight: "bold" }}>
                    Effective Income
                </label>
            </div>
        )};
    const totalCalculationFields = () => {
        return (
            <Fragment>
                <div className="lg:ml-0 col-6 md:col-6 lg:col-4">
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" prefix="$ "
                                required={true} 
                                name="principalPaid"
                                disabled={true}
                                // onValueChange={handleIncomeCalculationInputChange}
                                value={incomeCalculations?.totalRental}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-6 md:col-6 lg:col-4 ${isDesktopOrLaptop ? "" : "ml-3"}  ${isTablet ? "ml-7" : ""}`}>
                    <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off" prefix="$ "
                                required={true} 
                                name="principalPaid"
                                disabled={true}
                                onValueChange={
                                    handleIncomeCalculationInputChange
                                }
                                value={incomeCalculations?.totalBuy}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1">
                    <a
                        href="javascript:;"
                        className="relative pencil-icon-income"
                        onClick={() => setAppreciationModal(true)}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
            </Fragment>
        )};

    const header = () => {
        return (
            <div className={`grid header ${isTablet ? "text-align-center mt-3" : ""}`}>
                {isDesktopOrLaptop && (
                    <div className="col-12 md:col-2 lg:col-3">
                        {/* <label className="mt-3">Principal Paid</label> */}
                    </div>
                )}
                <div className="col-6 md:col-6 lg:col-4">
                    <label className="">Rental</label>
                </div>
                <div className="col-6 md:col-6 lg:col-4">
                    <label className="">Buy</label>
                </div>
            </div>
        );
    };

    const purchasepriceheader = () => {
        return (
            <Fragment>
                <div>
                    <div className="col-12 md:col-12 lg:col-3">
                        <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Purchase Price</label>
                    </div>
                    <div className="col-6 md:col-12 lg:col-4">
                        <div className={`${isDesktopOrLaptop ? "field" : "widthcls"}`}>
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off"
                                    required={true} prefix="$ "
                                    name="purchasePrice"
                                    disabled={false}
                                    min={0}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeLeftCalculations({
                                                name: "purchasePrice",
                                                value: e.value,
                                            })
                                        );
                                        handleLeftCalculations(
                                            "purchasePrice",
                                            e.value
                                        );
                                    }}
                                    value={leftContentValues?.purchasePrice}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            <div className="grid">
                <div>
                    <div className="col-12 md:col-12 lg:col-3">
                        <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Down Payment</label>
                    </div>
                    <div className="col-12 md:col-12 lg:col-4">
                        <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                            <span className="p-inputgroup"  style={{
                            width: "100%",
							}}>
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off" style={{ width: '6em' }}
                                    required={true}
                                    name="downPaymentPercent"
                                    disabled={false} suffix=" %"
                                    min={3.5}
                                    max={100}
                                    maxFractionDigits={2}
                                    value={
                                        leftContentValues?.downPaymentPercent
                                    }
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeLeftCalculations({
                                                name: "downPaymentPercent",
                                                value: e.target.value,
                                            })
                                        );
                                        // if (e.target.value) {
                                        //     handleLeftCalculations(
                                        //         "downPaymentPercent",
                                        //         e.target.value
                                        //     );
                                        // }
                                        // handleLeftCalculations(
                                        //     "downPaymentPercent",
                                        //     e.target.value
                                        // );
                                    }}
                                    onBlur={(e) => {
                                        if (
                                            !e.target.value ||
                                            e.target.value === "0"
                                        ) {
                                            dispatch(
                                                changeLeftCalculations({
                                                    name: "downPaymentPercent",
                                                    value: 3.5,
                                                })
                                            );
                                            handleLeftCalculations(
                                                "downPaymentPercent",
                                                3.5
                                            );
                                        } else {
                                            handleLeftCalculations(
                                                "downPaymentPercent",
                                                e.target.value
                                            );
                                        }
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>    
                <div>                
                    {!isDesktopOrLaptop && (
                        <div className="col-12 md:col-12 lg:col-3 margin-top-dp">                
                        </div>
                    )}
                    <div className="col-12 md:col-6 lg:col-4">
                        <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                            <div className="p-inputgroup">
                                {/* <span
                                className="p-input"
                                style={{
                                    width: "100%",
                                }}
                            > */}
                                <InputNumber
                                    className="vw-inputnumber-with-border"
                                    autoComplete="off" mode="currency" currency="USD"
                                    required={true}
                                    name="downPayment"
                                    locale="en-US"
                                    disabled={false}
                                    // mode="decimal"
                                    // minFractionDigits={2}
                                    onValueChange={(e) => {
                                        dispatch(
                                            changeLeftCalculations({
                                                name: "downPayment",
                                                value: e.target.value,
                                            })
                                        );
                                        if (e.target.value) {
                                            handleLeftCalculations(
                                                "downPayment",
                                                e.target.value
                                            );
                                        } else {
                                            dispatch(
                                                changeLeftCalculations({
                                                    name: "downPayment",
                                                    value: 0,
                                                })
                                            );
                                            handleLeftCalculations(
                                                "downPayment",
                                                0
                                            );
                                        }
                                    }}
                                    // onBlur={(e) => {
                                    //     if (!e.target.value) {
                                    //         dispatch(
                                    //             changeLeftCalculations({
                                    //                 name: "downPayment",
                                    //                 value: 3.5,
                                    //             })
                                    //         );
                                    //         handleLeftCalculations(
                                    //             "downPayment",
                                    //             3.5
                                    //         );
                                    //     } else {
                                    //         handleLeftCalculations(
                                    //             "downPayment",
                                    //             e.target.value
                                    //         );
                                    //     }
                                    // }}
                                    value={leftContentValues?.downPayment}
                                // value={
                                //     leftContentValues?.downPayment
                                //         ? FormatAmount(
                                //               leftContentValues?.downPayment
                                //           )
                                //         : 0
                                // }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    }

    // const taxHeader = () => {
    //     return (
    //         <div className="grid mb-3">
    //             <div className="col-12 md:col-12 lg:col-4">
    //                 <label className="" style={{ fontWeight: "bold" }}>
    //                     Tax
    //                 </label>
    //             </div>
    //             <div className="col-12 md:col-12 lg:col-4"></div>
    //             <div className="col-12 md:col-12 lg:col-4"></div>
    //         </div>
    //     );
    // };

    // const yearsPropertyHeader = () => {
    //     return (
    //         <div className="grid mb-3">
    //             <div className="col-12 md:col-12 lg:col-4">
    //                 <label className="" style={{ fontWeight: "bold" }}>
    //                     Appreciation Addback
    //                 </label>
    //             </div>
    //             <div className="col-12 md:col-12 lg:col-4"></div>
    //             <div className="col-12 md:col-12 lg:col-4"></div>
    //         </div>
    //     );
    // };

    // const yearsPropertyHeaderSettings = () => {
    //     return (
    //         <div className="grid mb-3">
    //             <div className="col-12 md:col-12 lg:col-4">
    //                 <label className="" style={{ fontWeight: "bold" }}>
    //                     Appreciation Settings
    //                 </label>
    //             </div>
    //             <div className="col-12 md:col-12 lg:col-4"></div>
    //             <div className="col-12 md:col-12 lg:col-4"></div>
    //         </div>
    //     );
    // };

    return (
        <Fragment>
            
            <Panel
                header={`${isDesktopOrLaptop ? "" : "Income Calculations"}`}
                className="mb-2 IncomeCalculations"
            >
                {header()}
                <div className={`${isDesktopOrLaptop ? "align-text" : "align-text-left"}`}>
                    {/* {renderSelfIncome()}
                    {renderSpouseIncome()}
                    {othersIncome()} */}
                    
                    {grossIncome()}
                    {/* {standardDeduction()}
                    {standardDeductionEquivalent()}
                    {retirementDed()}
                    {mortgageInterest()}
                    {propertyTaxes()}*/}
                    {totalDeductions()}
                    {/* {taxHeader()} */}
                    {totalTax()}
                    {/* {afterTax()}
                    {addbackStandardDeduction()}
                    {incomeBeforeHousing()}
                    {rent()}
                    {hoaEquivalent()}
                    {principalPaid()}
                    {nonDeductibleInterest()}
                    {nonDeductiblePropertyTax()}*/}
                    {postHousingTakeHome()}
                    {/* {yearsPropertyHeaderSettings()}
                    {yearsPropertyHeldSettings()}
                    {yearsPropertyHeader()}
                    {yearsPropertyHeld()}
                    {sellingCosts()}
                    {netRealization()}
                    {returnPerAnnum()} */}
                    {totalCalculation()}
                    {/* {renderFooter()} */}
                    {grossIncomeModal && (
                        <GrossIncomeModal
                            grossIncomeModal={grossIncomeModal}
                            incomeCalculations={incomeCalculations}
                            handleIncomeCalculationInputChange={
                                handleIncomeCalculationInputChange
                            }
                            setGrossIncomeModal={setGrossIncomeModal}
                            handleCalculationSave={handleCalculationSave}
                        />
                    )}
                    {totalTaxModal && (
                        <TotalTaxModal
                            totalTaxModal={totalTaxModal}
                            incomeCalculations={incomeCalculations}
                            handleIncomeCalculationInputChange={
                                handleIncomeCalculationInputChange
                            }
                            setTotalTaxModal={setTotalTaxModal}
                            handleCalculationSave={handleCalculationSave}
                        />
                    )}

                    {taxDeductionModal && (
                        <TaxDeductionModal
                            taxDeductionModal={taxDeductionModal}
                            incomeCalculations={incomeCalculations}
                            handleIncomeCalculationInputChange={
                                handleIncomeCalculationInputChange
                            }
                            setTaxDeductionModal={setTaxDeductionModal}
                            handleCalculationSave={handleCalculationSave}
                        />
                    )}

                    {housingModal && (
                        <HousingModal
                            housingModal={housingModal}
                            incomeCalculations={incomeCalculations}
                            handleIncomeCalculationInputChange={
                                handleIncomeCalculationInputChange
                            }
                            setHousingModal={setHousingModal}
                            handleCalculationSave={handleCalculationSave}
                        />
                    )}

                    {appreciationModal && (
                        <AppreciationModal
                            appreciationModal={appreciationModal}
                            incomeCalculations={incomeCalculations}
                            handleIncomeCalculationInputChange={
                                handleIncomeCalculationInputChange
                            }
                            setAppreciationModal={setAppreciationModal}
                            handleCalculationSave={handleCalculationSave}
                        />
                    )}
                </div>
            </Panel>
        </Fragment>
    );
};

export default Tab2;
