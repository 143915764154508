import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

const IndirectIncomeModal = ({
    indirectIncomeModal,
    handleIndirectIncomeModal,
    indirectIncome,
    handleIndirectIncomeInputChange,
    handleSelfRentalModal,
    handleIndirectIncomeSave,
    handleSpouseRentalModal,
}) => {
    const onHide = () => {
        handleIndirectIncomeModal(false);
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const isTablet = useMediaQuery({
        query: '(min-width: 768px) and (max-width: 1024px)'
      });
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className={`${isDesktopOrLaptop ? "col-7 pl-0" : "col-12"}`}>
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-raised p-button p-button-lg"
                                onClick={() => onHide()}
                            />
                            <Button
                                label="Save"
                                className="p-button-raised p-button-warning p-button-lg"
                                onClick={() => handleIndirectIncomeSave(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSelfEmploymentIncome = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Self Employment Income</label>
                </div>
               <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfEmployeIncome"
                                prefix="$ "
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfEmployeIncome}
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                prefix="$ "
                                required={true}
                                name="spouseEmployeIncome"
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseEmployeIncome}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    const renderStockOptions = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Stock Options</label>
                </div>
                
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfStockOptions"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfStockOptions}
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="spouseStockOptions"
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseStockOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    const renderDividendInterest = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Dividend / Interest</label>
                </div>
            
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfDividendInterest"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfDividendInterest}
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseDividendInterest"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseDividendInterest}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    const renderSocialSecurityPension = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Social Security Pension</label>
                </div>
                
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="selfSocialSecurityPension"
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={
                                    indirectIncome?.selfSocialSecurityPension
                                }
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="spouseSocialSecurityPension"
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={
                                    indirectIncome?.spouseSocialSecurityPension
                                }
                            />
                        </div>
                    </div>
                </div>
                
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    const renderAlimony = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Alimony / Child Support</label>
                </div>
               <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfAlimony"
                                disabled={false}
                                prefix="$ "
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfAlimony}
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseAlimony"
                                prefix="$ "
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseAlimony}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    const renderRentalIncome = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Rental Income</label>
                </div>
               <div className="col-5 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfRentalIncome"
                                disabled={true}
                                prefix="$ "
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfRentalTotal}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1 text-left">
                    <a
                        href="javascript:;"
                        className=""
                        onClick={handleSelfRentalModal}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
                <div className="col-5 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseRentalIncome"
                                disabled={true}
                                prefix="$ "
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseRentalTotal}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1 text-left">
                    <a
                        href="javascript:;"
                        className=""
                        onClick={handleSpouseRentalModal}
                    >
                        <i className="pi pi-pencil" />
                    </a>
                </div>
            </div>
        );
    };

    const renderOther = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Other</label>
                </div>
              
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="selfOther"
                                prefix="$ "
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfOther}
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                name="spouseOther"
                                prefix="$ "
                                disabled={false}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseOther}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    const renderTotalGross = () => {
        return (
            <div className="grid w-100">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Total Gross</label>
                </div>
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="selfTotalGross"
                                disabled={true}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.selfTotalGross}
                            />
                        </div>
                    </div>
                </div>
                {(isDesktopOrLaptop || isTablet) && (
                <div className="col-1 md:col-1 lg:col-1"></div>
                )}
                <div className="col-6 md:col-3 lg:col-3">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                prefix="$ "
                                name="spouseTotalGross"
                                disabled={true}
                                onValueChange={handleIndirectIncomeInputChange}
                                value={indirectIncome?.spouseTotalGross}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-1 md:col-1 lg:col-1"></div>
            </div>
        );
    };

    // const renderSelfRentalModal = () => (
    //     <SelfRental
    //         selfRentalModal={selfRentalModal}
    //         // handleTotalDebtModal={handleTotalDebtModal}
    //         // debtPayments={debtPayments}
    //         // handleDebtPaymentsInputChange={handleDebtPaymentsInputChange}
    //     />
    // );

    return (
        <Fragment>
            <Dialog
                header="Indirect Income"
                visible={indirectIncomeModal}
                onHide={() => onHide()}
                position="top"
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw" }}
                footer={renderFooter()}
                className="GrossIncomeModal"
                closeOnEscape={false}
                draggable={false}
            >
                <div className="grid w-100">
                    <div className="col-12 md:col-3 lg:col-3"></div>
                    <div className="col-6 md:col-2 lg:col-4">
                        <h4>Self</h4>
                    </div>
                    <div className="col-6 md:col-2 lg:col-4">
                        <h4>Spouse</h4>
                    </div>
                </div>
                <div className={`${isDesktopOrLaptop ? "align-text" : ""}`}>
                    {renderSelfEmploymentIncome()}
                    {renderStockOptions()}
                    {renderDividendInterest()}
                    {renderSocialSecurityPension()}
                    {renderAlimony()}
                    {renderRentalIncome()}
                    {renderOther()}
                    {renderTotalGross()}
                </div>
            </Dialog>
        </Fragment>
    );
};

export default IndirectIncomeModal;