import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const Brokers= Loadable(lazy(() => import("./Brokers")));
const AddBroker= Loadable(lazy(() => import("./AddBroker/AddBroker")));

// const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
// const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const brokerRoutes = [
    {
        path: "/brokers",
        element: <Brokers />,
    },
    {
        path: "/brokers/add-new",
        element: <AddBroker />,
    },
];

export default brokerRoutes;
