import { FormatAmount } from "app/utils/utility.service";
import { changeMonthlyDeductibleExpenses } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { getDeductibleExpenseCalculation } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const TaxBreak = ({ taxBreakModal, handleTaxBreakModal,handleExpensesSave }) => {
	const taxDedubtibleExpenseValues = useSelector(getDeductibleExpenseCalculation);
	const dispatch = useDispatch();
	const onHide = () => {
		handleTaxBreakModal(false);
	};
    const dollar = '$';
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
	const renderFooter = () => {
		return (
			<div>
				<div className="grid">
					<div className="col-3"></div>
					<div className={`${isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"}`}>
						<div style={{ marginLeft: "-0.5rem" }}>
							<Button
								label="Cancel"
								className="p-button-raised p-button p-button-lg"
								onClick={() => onHide()}
							/>
							<Button
								label="Save"
								className={`p-button-raised p-button-warning p-button-lg  ${isDesktopOrLaptop ? "" : "ml-5"}`}
								onClick={handleExpensesSave}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderHeader = () => {
		return (
			<p style={{ color: "#333", marginBottom: 0 }}>
				Owner gets tax break on interest and property taxes at their
				marginal tax rates
			</p>
		);
	};

	return (
        <Dialog
            header="Deductible Expenses"
            visible={taxBreakModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={renderFooter()}
            className="MonthlyExpenseModal"
            closeOnEscape={false}
            draggable={false}
        >
             {isDesktopOrLaptop ? (
                <Fragment>
			    {renderHeader()}
                </Fragment>
             ): (
                null
             )}
            <div className={`${isDesktopOrLaptop ? "text-right mt-4" : ""}`}>
                <div className="grid">
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>
                            Interest On First Mortgage
                        </label>
                    </div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="interestOnFirstPrincipal"
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.interestOnFirst
                                            ? (
                                                  parseFloat(taxDedubtibleExpenseValues?.interestOnFirst).toFixed()
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className={`${isDesktopOrLaptop ? "col-3" : "col-12"}`}>
                        <label className={`mt-2 ${isDesktopOrLaptop ? "" : "ml-3"}`}>
                            Interest On Second Mortgage
                        </label>
                    </div>
                    <div className={`${isDesktopOrLaptop ? "col-7" : "col-12"}`}>
                        <div className="field">
                            <div className="p-inputgroup">
                                  <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="interestOnSecondPrincipal"
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.interestOnSecondPrincipal
                                            ? FormatAmount(
                                                  parseFloat(taxDedubtibleExpenseValues?.interestOnSecondPrincipal).toFixed()
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-3">
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>Property Taxes</label>
                    </div>
                    <div className="col-12 md:col-12 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="propertyTax"
                                    disabled={true}
                                    suffix=" %"
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    value={
                                        taxDedubtibleExpenseValues?.propertyTax
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-1"></div>
                    <div className="col-12 md:col-12 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="dedPropertyTax"
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.dedPropertyTax
                                            ?  (taxDedubtibleExpenseValues?.dedPropertyTax)
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-3">
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>PMI</label>
                    </div>
                    <div className="col-12 md:col-12 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="taxPmiPercentage"
                                    disabled={false}
                                    suffix=" %"
                                    min={0} max={100}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    onValueChange={(e) => {
                                        //e.target.value = e.target.value.replace("%", "");
                                        dispatch(
                                            changeMonthlyDeductibleExpenses({
                                                name: "taxPmiPercentage",
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                    value={
                                        taxDedubtibleExpenseValues?.taxPmiPercentage
                                            ? taxDedubtibleExpenseValues?.taxPmiPercentage
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-1"></div>
                    <div className="col-12 md:col-12 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="taxPmiValue"
                                    disabled={true}
                                    prefix="$ "
                                    value={
                                        taxDedubtibleExpenseValues?.taxPmiValue
                                            ? (
                                                  taxDedubtibleExpenseValues?.taxPmiValue
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Net Expense */}
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-3">
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>
                            Total Tax Deductible Expense
                        </label>
                    </div>
                    <div className="col-12 md:col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="totalTaxDeductible"
                                    prefix="$ "
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.totalTaxDeductible
                                            ? (
                                                  taxDedubtibleExpenseValues?.totalTaxDeductible
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>Non Deductible Interest</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="nonDeductibleInterest"
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.nonDeductibleInterest
                                            ?  (
                                                  taxDedubtibleExpenseValues?.nonDeductibleInterest
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>
                            Non Deductible Property Tax
                        </label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                               <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="rent"
                                    prefix="$ "
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.nonDeductiblePropertyTax
                                            ? (
                                                  taxDedubtibleExpenseValues?.nonDeductiblePropertyTax
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`grid ${isDesktopOrLaptop ? "" : "taxbreak bold"}`}>
                    {isDesktopOrLaptop && (
                        <div className="col-12 md:col-12 lg:col-3"></div>
                    )}
                    <div className="col-6 md:col-6 lg:col-3 text-center">
                        <label>Annual</label>
                    </div>
                    {isDesktopOrLaptop && (
                        <div className="col-12 md:col-12 lg:col-1"></div>
                    )}
                    <div className="col-6 md:col-6 lg:col-3 text-center">
                        <label>Monthly</label>
                    </div>
                </div>

                <div className={`grid ${isDesktopOrLaptop ? "" : "taxbreak"}`}>
                    
                    <div className="col-12 md:col-12 lg:col-3">
                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>Tax Break</label>
                    </div>
                    <div className="col-6 md:col-6 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                            <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="annualTaxBreak"
                                    prefix="$ "
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.annualTaxBreak
                                            ? (
                                                taxDedubtibleExpenseValues?.annualTaxBreak
                                            )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    
                    {isDesktopOrLaptop && (
                    <div className="col-12 md:col-12 lg:col-1"></div>
                    )}
                    <div className="col-6 md:col-6 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                               <InputNumber
                                    className="vw-input-nfl-border"
                                    autoComplete="off"
                                    required={true}
                                    name="rent"
                                    prefix="$ "
                                    disabled={true}
                                    value={
                                        taxDedubtibleExpenseValues?.monthlyTaxBreak
                                            ? (
                                                taxDedubtibleExpenseValues?.monthlyTaxBreak
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default TaxBreak;
