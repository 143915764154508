import { Loading } from "app/components";
import { SuccessToastConfig, ErrorToastConfig } from "app/utils/ToastConstants";
import { getSessionUserInformation } from "app/utils/utility.service";
import { FormatAmount } from "app/utils/utility.service";
import { insertLogs } from "app/views/home-purchase/HomePurchaseStep3/homepurchase.service";
import { deleteRentalRecord } from "app/views/home-purchase/HomePurchaseStep3/homepurchase.service";
import {
    insertRentalIncome,
    updateRentalIncome,
} from "app/views/home-purchase/HomePurchaseStep3/homepurchase.service";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getIndirectRentalIncome,
    getSelfRentalList,
    onAddRental,
    onRemoveRental,
    storeIndirectIncomeTotalRental,
    storeRental,
} from "../../../../HomePurchaseStep3Slice";
import EditSelfRental from "./EditSelfRental";
import { InputNumber } from "primereact/inputnumber";
import { useMediaQuery } from "react-responsive";

const SelfRental = ({ selfRentalModal, handleSelfRentalModal }) => {
    const selfRentalList = useSelector(getSelfRentalList);
    const [editSelfRentalModal, setEditSelfRentalModal] = useState(false);
    const [currentRentalData, setCurrentRentalData] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const sessionValues = getSessionUserInformation();
    const rentalIncomeSelector = useSelector(getIndirectRentalIncome);

    const dispatch = useDispatch();
    const toast = useRef();

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleRemoveRental = async (index) => {
        // Call Delete API
        if (window.confirm("Are you sure you want to delete the record?")) {
            const record = selfRentalList[index];
            if (record?.id) {
                const deletedRecord = await deleteRentalRecord(record.id);
                if (deletedRecord) {
                    const logsPayload = {
                        description: "Deleted Rental Income",
                    };
                    insertLogs(logsPayload);
                    let selfRentalTotal = Number(
                        rentalIncomeSelector.selfRentalTotal
                    );
                    selfRentalTotal = selfRentalTotal - Number(record.nett);
                    setCurrentRentalData(null);
                    setCurrentIndex(null);
                    dispatch(
                        storeIndirectIncomeTotalRental({
                            type: "Self",
                            total: selfRentalTotal,
                        })
                    );
                    showSuccess("Self Rental Record deleted successfully");
                    dispatch(onRemoveRental({ type: "Self", index }));
                } else {
                    showError("Something Went Wrong. Please try again later");
                    return;
                }
            } else {
                setCurrentRentalData(null);
                setCurrentIndex(null);
                dispatch(onRemoveRental({ type: "Self", index }));
            }
        }
    };

    const handleEditSelfRentalModal = (index) => {
        if (editSelfRentalModal === true) {
            setCurrentRentalData(null);
            setCurrentIndex(null);
        } else {
            setCurrentRentalData(selfRentalList[index]);
            setCurrentIndex(index);
        }
        setEditSelfRentalModal(!editSelfRentalModal);
    };

    const handleIndexRentalSave = async (index, newPayload) => {
        let payload = { ...newPayload };
        payload.userId = sessionValues.id;
        payload.type = "Self";
        setIsLoading(true);
        let rentalRecord = {};
        if (!payload.id) {
            rentalRecord = await insertRentalIncome(payload);
        } else {
            delete payload.createdAt;
            delete payload.updatedAt;
            rentalRecord = await updateRentalIncome(payload.id, payload);
        }
        if (rentalRecord) {
            const logsPayload = {
                description: "Stored Rental Income",
            };
            insertLogs(logsPayload);
            showSuccess("Rental Income Record Saved successfully");
            dispatch(
                storeRental({ type: "Self", index, payload: rentalRecord })
            );
            setEditSelfRentalModal(!editSelfRentalModal);
            const rentalList = [...selfRentalList];
            rentalList[index] = rentalRecord;
            let total = 0;
            if (rentalList.length > 0) {
                rentalList.forEach((rl) => {
                    total += Number(rl.nett);
                });
            }
            dispatch(storeIndirectIncomeTotalRental({ type: "Self", total }));
            setIsLoading(false);
        } else {
            setIsLoading(false);
            showError("Something went wrong. Please try again later.");
            return;
        }
    };

    const renderEditSelfRentalModal = () => {
        return (
            <EditSelfRental
                editSelfRentalModal={editSelfRentalModal}
                handleEditSelfRentalModal={handleEditSelfRentalModal}
                index={currentIndex}
                currentRentalData={currentRentalData}
                handleIndexRentalSave={handleIndexRentalSave}
            />
        );
    };

    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="lg:col-3"></div>
                    <div className="col-12 lg:col-6 lg:pl-0">
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-raised p-button p-button-lg"
                                onClick={handleSelfRentalModal}
                            />
                            <Button
                                label="Add New"
                                className="p-button-raised p-button-warning p-button-lg min-width"
                                onClick={() =>
                                    dispatch(onAddRental({ type: "Self" }))
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const renderTotal = () => {
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">
                    <label
                        className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}
                    >
                        Total
                    </label>
                </div>
                <div className="col-12 lg:col-7">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-input-nfl"
                                autoComplete="off"
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                                required={true}
                                name="rent1"
                                disabled={true}
                                value={
                                    rentalIncomeSelector.selfRentalTotal
                                        ? rentalIncomeSelector.selfRentalTotal
                                        : 0
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <Fragment>
            {isLoading && <Loading />}
            <Toast ref={toast} />
            <Dialog
                header="Rental Income - Self"
                position="top"
                visible={selfRentalModal}
                onHide={handleSelfRentalModal}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw" }}
                footer={renderFooter()}
                className="GrossIncomeModal"
                closeOnEscape={false}
                draggable={false}
            >
                <div
                    className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}
                >
                    {/* Mortgage Payments */}
                    {selfRentalList &&
                        selfRentalList.map((rental, index) => {
                            return (
                                <div className="grid">
                                    <div className="col-12 lg:col-3">
                                        <label
                                            className={`mt-3 ${
                                                isDesktopOrLaptop ? "" : "ml-3"
                                            }`}
                                        >
                                            Rent {index + 1}
                                        </label>
                                    </div>

                                    <div className="col-7">
                                        <div className="field">
                                            <div className="p-inputgroup">
                                                <InputNumber
                                                    className="vw-input-nfl"
                                                    autoComplete="off"
                                                    required={true}
                                                    name="rent1"
                                                    disabled={true}
                                                    mode="currency"
                                                    currency="USD"
                                                    locale="en-US"
                                                    value={
                                                        rental?.nett
                                                            ? rental?.nett
                                                            : 0
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        href="javascript:;"
                                        className="relative pencil-icon"
                                        onClick={() =>
                                            handleEditSelfRentalModal(index)
                                        }
                                    >
                                        <i className="pi pi-pencil" />
                                    </a>
                                    <a
                                        href="javascript:;"
                                        className="relative pencil-icon ml-4"
                                        onClick={() =>
                                            handleRemoveRental(index)
                                        }
                                    >
                                        <i className="pi pi-trash" />
                                    </a>
                                </div>
                            );
                        })}

                    {renderTotal()}
                </div>
            </Dialog>
            {renderEditSelfRentalModal()}
        </Fragment>
    );
};

export default SelfRental;
