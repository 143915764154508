import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import { FormatAmount } from 'app/utils/utility.service';


const IndirectIncomeGraphModal = ({
    indirectIncomeGraphModal,
    handleIndirectIncomeGraphModal,
    indirectIncome,
}) => {
    const onHide = () => {
        handleIndirectIncomeGraphModal(false);
    };

    

    const [basicData, setBasicData] = useState({
        labels: [
            "Employment Income",
            "Stock Options",
            "Dividend / Interest",
            "Social Security Pension",
            "Alimony / Child Support",
            "Rental Income",
        ],
        datasets: [
            {
                label: `Self - ${
                    indirectIncome?.selfTotalGross
                        ? FormatAmount(indirectIncome?.selfTotalGross)
                        : 0
                }`,
                backgroundColor: "#42A5F5",
                data: [
                    indirectIncome?.selfEmployeIncome,
                    indirectIncome?.selfStockOptions,
                    indirectIncome?.selfDividendInterest,
                    indirectIncome?.selfSocialSecurityPension,
                    indirectIncome?.selfAlimony,
                    indirectIncome?.selfRentalTotal,
                ],
            },
            {
                label: `Spouse - ${
                    indirectIncome?.selfTotalGross
                        ? FormatAmount(indirectIncome?.spouseTotalGross)
                        : 0
                }`,
                backgroundColor: "#FFA726",
                data: [
                    indirectIncome?.spouseEmployeIncome,
                    indirectIncome?.spouseStockOptions,
                    indirectIncome?.spouseDividendInterest,
                    indirectIncome?.spouseSocialSecurityPension,
                    indirectIncome?.spouseAlimony,
                    indirectIncome?.spouseRentalTotal,
                ],
            },
        ],
    });

    useEffect(()=>{
        setBasicData((prev) => ({
            ...prev,
            datasets: [
                {
                    ...prev.datasets[0],
                    label: `Self - ${
                        indirectIncome?.selfTotalGross
                            ? FormatAmount(indirectIncome?.selfTotalGross)
                            : 0
                    }`,

                    data: [
                        indirectIncome?.selfEmployeIncome,
                        indirectIncome?.selfStockOptions,
                        indirectIncome?.selfDividendInterest,
                        indirectIncome?.selfSocialSecurityPension,
                        indirectIncome?.selfAlimony,
                        indirectIncome?.selfRentalTotal,
                    ],
                },
                {
                    ...prev.datasets[1],
                    label: `Spouse - ${
                        indirectIncome?.selfTotalGross
                            ? FormatAmount(indirectIncome?.spouseTotalGross)
                            : 0
                    }`,
                    data: [
                        indirectIncome?.spouseEmployeIncome,
                        indirectIncome?.spouseStockOptions,
                        indirectIncome?.spouseDividendInterest,
                        indirectIncome?.spouseSocialSecurityPension,
                        indirectIncome?.spouseAlimony,
                        indirectIncome?.spouseRentalTotal,
                    ],
                },
            ],
        }));
    },[indirectIncomeGraphModal])

    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
            y: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
        },
    };

    

    return (
        <Dialog
            header="Indirect Income Graph"
            visible={indirectIncomeGraphModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw" }}
            // footer={renderFooter()}
            className="MonthlyExpenseModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="align-text">
                <Chart type="bar" data={basicData} options={basicOptions} />
            </div>
        </Dialog>
    );
};

export default IndirectIncomeGraphModal