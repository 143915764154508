import { Panel } from "primereact/panel";
import { useState } from "react";
import { ASSUMPTION_CONSTANTS } from "../investment_constants";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const Assumptions = ({handleAssumptionsSave, handleAssumptionsInputChange, assumptionsInput}) => {

    const amortizationYears = [
        { name: "15 Years", code: 15 },
        { name: "30 Years", code: 30 },
        { name: "40 Years", code: 40 }
    ]; 
    
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-6">
                        <Button
                            label="Save"
                            className="p-button-raised p-button-warning p-button-lg"
                            onClick={() => handleAssumptionsSave()}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Panel className="Assumptions">
                <div className={`mb-2`}>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4 mt-3">
                            <label className="">
                                Percentage Down
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="percentageDown"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.percentageDown}
                                        // value={leftContentassumptionsInput?.purchasePrice ? FormatAmount(leftContentassumptionsInput?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">
                                Interest Rate
                            </label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="interestRate"
                                        suffix=" %"
                                        min={0} max={100}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.interestRate}
                                        // value={leftContentassumptionsInput?.purchasePrice ? FormatAmount(leftContentassumptionsInput?.purchasePrice) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Property Tax Rate</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="propertyTaxRate"
                                        suffix=" %"
                                        min={0} max={100}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.propertyTaxRate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Amortization</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <Dropdown
                                    className="vw-dropdown vw-dropdown-with-border"
                                    value={assumptionsInput?.amortization}
                                    options={amortizationYears}
                                    required={true}
                                    name="amortization"
                                    onChange={handleAssumptionsInputChange}
                                    optionLabel="name"
                                    optionValue="code"
                                />
                                    {/* <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="amortization"
                                        prefix=""
                                        maxFractionDigits={0}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.amortization}
                                    /> */}
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Property Management Fees</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="propertyManagementFees"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.propertyManagementFees}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Vacancy Factor</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="vacancyFactor"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.vacancyFactor}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Maintenance</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="maintenance"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0} max={100}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.maintenance}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                    <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3"><strong>Property Information</strong></label>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Built Up</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="builtUp"
                                        prefix=""
                                        maxFractionDigits={2}
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.builtUp}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-4">
                            <label className="mt-3">Construction Cost</label>
                        </div>
                        <div className="col-12 md:col-12 lg:col-8">
                            <div className="field">
                                <div className="p-inputgroup">
                                    <InputNumber
                                        className="vw-inputnumber-with-border"
                                        autoComplete="off"
                                        required={true}
                                        name="constructionCost"
                                        prefix="$ "
                                        disabled={false}
                                        onValueChange={
                                            handleAssumptionsInputChange
                                        }
                                        value={assumptionsInput?.constructionCost}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {renderFooter()}
                </div>
            </Panel>
    )
}

export default Assumptions;