import { PMT } from "app/utils/utility.service";
import { getSessionUserSettings } from "app/utils/utility.service";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const initialRentalIncomeState = {
    nett: 0,
    rent: 0,
    pi: 0,
    t: 0,
    i: 0,
    balance: 0,
    intt: 0,
    purchase: 0,
    propertyTax: 1.25,
};

const EditSelfRental = ({
    editSelfRentalModal,
    handleEditSelfRentalModal,
    index,
    currentRentalData,
    handleIndexRentalSave,
}) => {
    const [rentalIncome, setRentalIncome] = useState(initialRentalIncomeState);
    // const userSettings = getSessionUserSettings();
    const calcRentalIncomeCalculations = (newValues) => {
        let { balance, purchase, intt, propertyTax } = newValues;
        // const propertyTax = userSettings?.propertyTax
        //     ? Number(userSettings?.propertyTax)
        //     : 1.25;
        newValues.t = Math.round((purchase * (Number(propertyTax) / 100 / 12))*1.3);
        // newValues.t = 1081;
        newValues.pi = Math.round(
            PMT(
                intt / 1200, // Annual interest into months
                360, // Total months for life of loan
                balance,
                0,
                0
            ) * -1
        );
        // newValues.i = Math.round(1250/12);
        newValues.nett =
            newValues.rent - (newValues.pi + newValues.t + newValues.i);

        setRentalIncome({ ...newValues });
    };

    useEffect(() => {
        if (currentRentalData) {
            setRentalIncome(currentRentalData);
        }
    }, [currentRentalData]);

    const onHide = () => {
        handleEditSelfRentalModal();
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })

    const handleRentalIncomeInputChange = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
        const newValues = {
            ...rentalIncome,
            [name]: value,
        };
        setRentalIncome(newValues);
        if (!!newValues.purchase?.toString() || !!newValues.balance?.toString() || !!newValues.intt?.toString()) {
            calcRentalIncomeCalculations(newValues);
        }
    };

    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="lg:col-3"></div>
                    <div className="col-12 lg:col-6 lg:pl-0">
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-raised p-button p-button-lg"
                                onClick={() => onHide()}
                            />
                            <Button
                                label="Save"
                                className="p-button-raised p-button-warning p-button-lg"
                                // onClick={handleExpensesSave}
                                onClick={() =>
                                    handleIndexRentalSave(index, rentalIncome)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderUI = () => {
        return (
            <Fragment>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Rent</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    name="rent"
                                    disabled={false}
                                    onValueChange={
                                        handleRentalIncomeInputChange
                                    }
                                    value={
                                        rentalIncome?.rent
                                            ? rentalIncome?.rent
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Mortgage Balance</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                               
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="balance"
                                    disabled={false}
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    onValueChange={
                                        handleRentalIncomeInputChange
                                    }
                                    value={
                                        rentalIncome?.balance
                                            ? rentalIncome?.balance
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Purchase</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="purchase"
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    disabled={false}
                                    onValueChange={
                                        handleRentalIncomeInputChange
                                    }
                                    value={
                                        rentalIncome?.purchase
                                            ? rentalIncome?.purchase
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Intt</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="intt"
                                    disabled={false}
                                    useGrouping={false}
                                    suffix=" %"
                                    min={0} max={100}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    onValueChange={
                                        handleRentalIncomeInputChange
                                    }
                                    value={
                                        rentalIncome?.intt
                                            ? rentalIncome?.intt
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Principal Interest</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                
                                <InputText
                                    className="vw-input-nfl"
                                    autoComplete="off"
                                    required={true}
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    name="pi"
                                    disabled={true}
                                    value={
                                        rentalIncome?.pi ? rentalIncome?.pi : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Taxes</label>
                    </div>
                    <div className="col-12 lg:col-4">
                        <div className="field">
                            <div className="p-inputgroup">

                                <InputText
                                    className="vw-input-nfl"
                                    autoComplete="off"
                                    required={true}
                                    name="t"
                                    disabled={true}
                                    value={
                                        rentalIncome?.t ? rentalIncome?.t : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3">
                        <div className="field">
                            <div className="p-inputgroup">
                               
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="propertyTax"
                                    disabled={false}
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    maxFractionDigits={2}
                                    onValueChange={
                                        handleRentalIncomeInputChange
                                    }
                                    value={
                                        rentalIncome?.propertyTax
                                            ? rentalIncome?.propertyTax
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Insurance and Expenses</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="i"
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    disabled={false}
                                    onValueChange={
                                        handleRentalIncomeInputChange
                                    }
                                    value={
                                        rentalIncome?.i ? rentalIncome?.i : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <label className="mt-3">Nett</label>
                    </div>
                    <div className="col-12 lg:col-7">
                        <div className="field">
                            <div className="p-inputgroup">
                                
                                <InputText
                                    className="vw-input-nfl"
                                    autoComplete="off"
                                    required={true}
                                    name="nett"
                                    mode="currency" currency="USD"
                                    locale="en-US"
                                    disabled={true}
                                    value={
                                        rentalIncome?.nett
                                            ? rentalIncome?.nett
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <Dialog
            header="Edit Rental"
            visible={editSelfRentalModal}
            onHide={() => onHide()}
            position="top"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={renderFooter()}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "align-text" : ""}`}>{renderUI()}</div>
        </Dialog>
    );
};

export default EditSelfRental;
