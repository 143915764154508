import axios from "axios";

class RestClient {
    async get(url, params = {}) {
        const userInfo = JSON.parse(sessionStorage.getItem("user"));
        try {
            return await axios.get(`${url}`, {
                headers: {
                    Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                },
                params,
            });
        } catch (err) {
            if (err?.response?.status === 200) {
                return err?.response?.data;
            } else {
                if (err?.response?.data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: err?.response?.data?.message };
                }
            }
        }
    }

    async post(url, params) {
        const lastUrl = url.substring(url.lastIndexOf("/") + 1);
        const userInfo = JSON.parse(sessionStorage.getItem("user"));

        let response = {};
        try {
            if (lastUrl === "authenticate" || lastUrl === "register") {
                response = await axios.post(`${url}`, params);
            } else {
                response = await axios.post(`${url}`, params, {
                    headers: {
                        Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                    },
                });
            }
            return response;
        } catch (err) {
            if (err?.response?.status === 200) {
                return err?.response?.data;
            } else {
                if (err?.response?.data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: err?.response?.data?.message };
                }
            }

            // throw new Error(err.response.data.message);
        }
    }

    async put(url, params) {
        const userInfo = JSON.parse(sessionStorage.getItem("user"));
        try {
            const response = await axios.put(`${url}`, params, {
                headers: {
                    Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                },
            });
            return response;
        } catch (err) {
            if (err?.response?.status === 200) {
                return err?.response?.data;
            } else {
                if (err?.response?.data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: err?.response?.data?.message };
                }
            }
        }
    }

    async delete(url, params = {}) {
        const userInfo = JSON.parse(sessionStorage.getItem("user"));
        try {
            const response = await axios.delete(`${url}`, {
                headers: {
                    Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                },
            });
            return response;
        } catch (err) {
            if (err?.response?.status === 200) {
                return err?.response?.data;
            } else {
                if (err?.response?.data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: err?.response?.data?.message };
                }
            }
        }
    }
}

export default RestClient;
