import { Loading } from "app/components";
import { SuccessToastConfig, ErrorToastConfig } from "app/utils/ToastConstants";
import { getSessionUserInformation } from "app/utils/utility.service";
import { FormatAmount } from "app/utils/utility.service";
import { deleteRentalRecord } from "app/views/home-purchase/HomePurchaseStep3/homepurchase.service";
import { insertRentalIncome, updateRentalIncome } from "app/views/home-purchase/HomePurchaseStep3/homepurchase.service";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndirectRentalIncome, getSpouseRentalList, onAddRental, onRemoveRental, storeIndirectIncomeTotalRental, storeRental } from "../../../../HomePurchaseStep3Slice";
import EditSpouseRental from "./EditSpouseRental";
import { InputNumber } from "primereact/inputnumber";
import { useMediaQuery } from "react-responsive";

const SpouseRental = ({spouseRentalModal, handleSpouseRentalModal}) => {
    const spouseRentalList = useSelector(getSpouseRentalList);
    const [editSpouseRentalModal, setEditSpouseRentalModal] = useState(false);
    const [currentRentalData, setCurrentRentalData] = useState(null);
    const [currentIndex,setCurrentIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const sessionValues = getSessionUserInformation();
    const rentalIncomeSelector = useSelector(getIndirectRentalIncome)

    const dispatch = useDispatch();
    const toast = useRef();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })

    const showSuccess = (message) => {
		toast.current.show(SuccessToastConfig(message));
	};

    const showError = (message) => {
		toast.current.show(ErrorToastConfig(message ? message : "Error"));
	};

    const handleRemoveRental = async (index) => {
        // Call Delete API
        if(window.confirm("Are you sure you want to delete the record?")) {
            const record = spouseRentalList[index];
            if (record?.id) {
                const deletedRecord = await deleteRentalRecord(record.id);
                console.log("deletedRecord",deletedRecord);
                if (deletedRecord) {
                    let spouseRentalTotal = Number(rentalIncomeSelector.spouseRentalTotal);
                    spouseRentalTotal = spouseRentalTotal-Number(record.nett);
                    setCurrentRentalData(null);
                    setCurrentIndex(null);
                    dispatch(storeIndirectIncomeTotalRental({ type: "Spouse", total:spouseRentalTotal }));
                    showSuccess("Rental Record deleted successfully");
                    dispatch(onRemoveRental({ type: "Spouse", index }));
                } else {
                    showError("Something Went Wrong. Please try again later");
                    return;
                }
            } else {
                setCurrentRentalData(null);
                setCurrentIndex(null);
                dispatch(onRemoveRental({ type: "Spouse", index }));
            }
        }
    }

    const handleEditSpouseRentalModal = (index) => {
        console.log("editSpouseRentalModal",editSpouseRentalModal);
        if (editSpouseRentalModal === true) {
            setCurrentRentalData(null);
            setCurrentIndex(null);
        } else {
            setCurrentRentalData(spouseRentalList[index]);
            setCurrentIndex(index);
        }
        setEditSpouseRentalModal(!editSpouseRentalModal);
    };

    const handleIndexRentalSave = async (index, newPayload) => {
        let payload = {...newPayload};
        payload.userId = sessionValues.id;
        payload.type = "Spouse";
        setIsLoading(true);
        let rentalRecord = {};
        if(!payload.id) {
            rentalRecord = await insertRentalIncome(payload);
        } else {
            delete payload.createdAt;
            delete payload.updatedAt;
            rentalRecord = await updateRentalIncome(payload.id, payload);
        }
        if(rentalRecord) {
            console.log(rentalRecord);
            showSuccess("Spouse Rental Income Record Saved successfully");
            dispatch(storeRental({ type: "Spouse", index, payload:rentalRecord }));
            setEditSpouseRentalModal(!editSpouseRentalModal);
            const rentalList = [...spouseRentalList];
            rentalList[index] = rentalRecord;
            let total = 0;
            if(rentalList.length > 0) {
                rentalList.forEach((rl)=>{
                    total += Number(rl.nett);
                })
            }
            dispatch(storeIndirectIncomeTotalRental({ type: "Spouse", total }));
            setIsLoading(false);
        } else {
            setIsLoading(false);
            showError("Something went wrong. Please try again later.");
            return;
        }
       
    }

    const renderEditSpouseRentalModal = () => {
        return (
            <EditSpouseRental
                editSpouseRentalModal={editSpouseRentalModal}
                handleEditSpouseRentalModal={handleEditSpouseRentalModal}
                index={currentIndex}
                currentRentalData={currentRentalData}
                handleIndexRentalSave={handleIndexRentalSave}
            />
        );
    }

    

    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="lg:col-3"></div>
                    <div className="col-12 lg:col-6 lg:pl-0">
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-raised p-button p-button-lg"
                                onClick={handleSpouseRentalModal}
                            />
                        <Button
                            label="Add New"
                            className="p-button-raised p-button-warning p-button-lg min-width"
                            onClick={() =>
                                dispatch(onAddRental({ type: "Spouse" }))
                            }
                        />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTotal =() => {
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">
                    <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>Total</label>
                </div>
                <div className="col-12 lg:col-7">
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputNumber
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="rent1"
                                disabled={true}
                                mode="currency" currency="USD"
                                locale="en-US"
                                value={
                                    rentalIncomeSelector.spouseRentalTotal
                                        ? FormatAmount(
                                              rentalIncomeSelector.spouseRentalTotal
                                          )
                                        : 0
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <Fragment>
            {isLoading && <Loading />}
            <Toast ref={toast} />
            <Dialog
                header="Rental Income - Spouse"
                position="top"
                visible={spouseRentalModal}
                onHide={handleSpouseRentalModal}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw" }}
                footer={renderFooter()}
                className="GrossIncomeModal"
                closeOnEscape={false}
                draggable={false}
            >
                <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                    {/* Mortgage Payments */}
                    {spouseRentalList &&
                        spouseRentalList.map((rental, index) => {
                            return (
                                <div className="grid">
                                    <div className="col-12 lg:col-3">
                                        <label className={`mt-3 ${isDesktopOrLaptop ? "" : "ml-3"}`}>
                                            Rent {index + 1}
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="field">
                                            <div className="p-inputgroup">
                                                <InputNumber
                                                    className="vw-input-nfl"
                                                    autoComplete="off"
                                                    required={true}
                                                    name="rent1"
                                                    mode="currency" currency="USD"
                                                    locale="en-US"
                                                    disabled={true}
                                                    value={
                                                        rental?.nett
                                                            ? FormatAmount(
                                                                  rental?.nett
                                                              )
                                                            : 0
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        href="javascript:;"
                                        className="relative pencil-icon"
                                        onClick={() =>
                                            handleEditSpouseRentalModal(index)
                                        }
                                    >
                                        <i className="pi pi-pencil" />
                                    </a>
                                    <a
                                        href="javascript:;"
                                        className="relative pencil-icon ml-4"
                                        onClick={() =>
                                            handleRemoveRental(index)
                                        }
                                    >
                                        <i className="pi pi-trash" />
                                    </a>
                                </div>
                            );
                        })}

                    {renderTotal()}
                </div>
            </Dialog>
            {renderEditSpouseRentalModal()}
        </Fragment>
    );
}

export default SpouseRental;