import { useMediaQuery } from "react-responsive";
import IncomeCalculationsFooter from "./common/IncomeCalculationsFooter";
import IncomeCalculationsHeader from "./common/IncomeCalculationsHeader";

const { Dialog } = require("primereact/dialog");
const { InputNumber } = require("primereact/inputnumber");

const TaxDeductionModal = ({
    taxDeductionModal,
    incomeCalculations,
    handleIncomeCalculationInputChange,
    setTaxDeductionModal,
    handleCalculationSave
}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      });
    const standardDeduction = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Standard Deduction </label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="standardDeduction"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.standardDeduction}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="standardDeduction"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    // value={incomeCalculations?.standardDeduction}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const standardDeductionEquivalent = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">
                        Standard Deduction Equivalent
                    </label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="standardDeductionEquivalent"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    // value={incomeCalculations?.standardDeductionEquivalent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="standardDeductionEquivalent"
                                    disabled={false}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={
                                        incomeCalculations?.standardDeductionEquivalent
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const retirementDed = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">
                        Retirement Ded (401K, 403b..)
                    </label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="retirementDed"
                                    prefix="$ "
                                    disabled={false}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.retirementDed}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="retirementDed"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.retirementDed}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const mortgageInterest = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Mortgage Interest</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="mortgageInterest"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    // value={incomeCalculations?.mortgageInterest}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    prefix="$ "
                                    name="mortgageInterest"
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.mortgageInterest}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const propertyTaxes = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Property Taxes</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="propertyTaxes"
                                    prefix="$ "
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    // value={incomeCalculations?.propertyTaxes}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="mortgageInterest"
                                    prefix="$ "
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.propertyTaxes}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const totalDeductions = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                    <label className="mt-3">Total Deductions</label>
                </div>
                <div className={`${isDesktopOrLaptop ? "grid-grossIncome" : "grid"}`}>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="preTaxGross"
                                    prefix="$ "
                                    disabled={true}
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalDeductionsRent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 md:col-6 lg:col-8">
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputNumber
                                    className="vw-inputnumber-with-border w-100"
                                    autoComplete="off"
                                    required={true}
                                    name="preTaxGrossBuy"
                                    disabled={true}
                                    prefix="$ "
                                    onValueChange={
                                        handleIncomeCalculationInputChange
                                    }
                                    value={incomeCalculations?.totalDeductionsBuy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Total Deductions"
            position="top"
            visible={taxDeductionModal}
            onHide={() => setTaxDeductionModal(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            className="GrossIncomeModal"
            closeOnEscape={false}
            draggable={false}
        >
            <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
                <IncomeCalculationsHeader />
                {standardDeduction()}
                {standardDeductionEquivalent()}
                {retirementDed()}
                {mortgageInterest()}
                {propertyTaxes()}
                {totalDeductions()}
                <IncomeCalculationsFooter
                    handleCalculationSave={handleCalculationSave}
                    setModal={setTaxDeductionModal}
                />
            </div>
        </Dialog>
    );
};

export default TaxDeductionModal;