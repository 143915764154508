import BasicInformation from "./BasicInformation/BasicInformation";
import OwnHouse from "./OwnHouse/OwnHouse";

const Tab1 = ({
    handleLeftCalculations,
    handleExpensesSave,
    handleOwnHouseSave,
    mortgageStructureModal,
    handleMortgageStructureModal,
    taxBreakModal,
    handleTaxBreakModal
}) => {
    
    return (
        <div>
            
            <BasicInformation handleLeftCalculations={handleLeftCalculations} />
            <OwnHouse
                handleLeftCalculations={handleLeftCalculations}
                handleExpensesSave={handleExpensesSave}
                handleOwnHouseSave={handleOwnHouseSave}
                mortgageStructureModal={mortgageStructureModal}
                handleMortgageStructureModal={handleMortgageStructureModal}
                taxBreakModal={taxBreakModal}
                handleTaxBreakModal={handleTaxBreakModal}
            />
        </div>
    );
};

export default Tab1;
